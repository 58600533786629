import { ReactElement } from "react";

import { generateGraphql } from "@rivial-security/generategraphql";
import { modules, resources } from "@rivial-security/role-utils";
import { System } from "@rivial-security/schema-types";

import { GENERIC_FIELD_TYPES } from "@utils/GenericComponents/GenericEditFieldV3/constants/GENERIC_FIELD_TYPES";
import GenericEditFieldV3 from "@utils/GenericComponents/GenericEditFieldV3/GenericEditFieldV3";

export interface LastReviewedProps {
  item: Pick<System, "id" | "lastReviewed">;
  updateItemById: VoidFunction;
  fieldContext: string;
}

const LastReviewed = ({ item, updateItemById, ...props }: LastReviewedProps): ReactElement => {
  const module = modules.RISK;
  const resource = resources.INFORMATION_SYSTEM;
  const field = "lastReviewed";

  return (
    <GenericEditFieldV3
      module={module}
      resource={resource}
      field={field}
      item={item}
      mutation={generateGraphql(resource, [field]).updateMutation}
      tooltip={"The date this system's risk was last reviewed"}
      inputType={GENERIC_FIELD_TYPES.DATE}
      updateItemById={updateItemById}
      inputConfig={{
        dateOnly: true,
      }}
      displayConfig={{
        dateOnly: true,
      }}
      {...props}
    />
  );
};

export default LastReviewed;
