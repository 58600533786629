"use strict";
/**
 * NOTE: requires image-size to be installed separately for NODE.JS environments
 * (do not install it as dependency of this package or it will break the frontend in React 17)
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBase64ImageDimensions = void 0;
/**
 * Given a base64 data url of an image finds the width and height
 * @param {string} base64String - base64 encoded image with the data prefix
 * @return {Promise<{width, height}>}
 */
const getBase64ImageDimensions = async ({ base64String }) => {
    let width;
    let height;
    let base64 = base64String;
    if (base64String.includes(";base64,")) {
        base64 = base64String.split(";base64,").pop();
    }
    //Browser based retrieval of size
    try {
        //REFERENCE: https://stackoverflow.com/questions/17774928/js-get-image-width-and-height-from-the-base64-code
        const img = new Image();
        img.src = base64String;
        await img.decode();
        width = img.width;
        height = img.height;
    }
    catch (e) {
        console.error(e);
    }
    //NodeJS based retrieval of size
    if (!width || !height) {
        try {
            const { default: sizeOf } = await Promise.resolve().then(() => __importStar(require("image-size")));
            //REFERENCE: https://stackoverflow.com/questions/54804754/nodejs-how-to-use-image-size-with-base64-image
            const img = Buffer.from(base64, "base64");
            const dimensions = sizeOf(img);
            width = dimensions.width;
            height = dimensions.height;
        }
        catch (e) {
            console.error(e);
        }
    }
    return {
        width,
        height,
    };
};
exports.getBase64ImageDimensions = getBase64ImageDimensions;
