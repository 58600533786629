import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "@utils/Functions/deepDelete";
import { resources } from "role-utils";

/**
 * Deletes a Vulnerability resource in the DB with all linked resources.
 * @param {string[]} itemsIDs - array of items IDs to delete
 * @param {object} config - configuration object
 * @param {boolean} config.deleteObservations - if true, will delete all Observations associated with this Vulnerability
 * @param {function} onJobStarted - callback function to get job
 * @returns {Promise<void>}
 */
export const deleteVulnerability = async (itemsIDs, { deleteObservations = true }, onJobStarted) => {
  if (!Array.isArray(itemsIDs)) {
    ErrorLogger("[deleteVulnerability] Missing Vulnerability IDs array");
    return;
  }

  return await deepDelete({
    typename: resources.VULNERABILITY,
    itemsIDs,
    config: {
      deleteObservations,
    },
    onJobStarted,
  });
};
