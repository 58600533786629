import type { PropsWithChildren, ReactElement } from "react";

import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ModalCloseOptions {
  isDirty?: boolean;
  reset?: VoidFunction;
  confirmMessage?: string;
}

interface ModalContextType {
  activeModal: string | null;
  openModal: (modalId: string) => void;
  closeModal: (options?: ModalCloseOptions) => void;
}

export interface UseModal {
  isOpen: boolean;
  openModal: VoidFunction;
  closeModal: (options?: ModalCloseOptions) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider = ({ children }: PropsWithChildren): ReactElement => {
  const { t } = useTranslation();
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const openModal = useCallback((modalId: string): void => {
    setActiveModal(modalId);
  }, []);

  const closeModal = useCallback((options?: ModalCloseOptions): void => {
    const { reset, isDirty, confirmMessage } = options ?? {};
    if (isDirty) {
      const shouldClose = confirm(confirmMessage ?? t("form.are you sure"));

      if (!shouldClose) {
        return;
      }
    }

    reset?.();
    setActiveModal(null);
  }, []);

  const value = useMemo(
    () => ({
      activeModal,
      openModal,
      closeModal,
    }),
    [activeModal, openModal, closeModal],
  );

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const useModal = (modalId: string): UseModal => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }

  return {
    isOpen: context.activeModal === modalId,
    openModal: () => context.openModal(modalId),
    closeModal: (options?: ModalCloseOptions) => context.closeModal(options),
  };
};
