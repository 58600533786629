export const systemsByOwnerGroup = /* GraphQL */ `
  query SystemsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelSystemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    systemsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ownerGroup
        name
        description
        pointOfContact {
          id
          ownerGroup
          firstName
          lastName
          title
          email
        }
        hosting
        adminPointOfContact {
          id
          ownerGroup
          firstName
          lastName
          title
          email
        }
        tags(limit: 100) {
          items {
            __typename
            id
            tag {
              id
              name
              description
              fontColor
              backgroundColor
            }
          }
        }
        lastReviewed
        inherentRisk
        residualRisk
        riskRating
        greatestEnterpriseRisk
        greatestBusinessRisk
        greatestKeyRiskIndicator
        questionnaires(limit: 100) {
          items {
            id
            questionnaire {
              name
              id
              status
              assignees {
                items {
                  id
                  questionnaireID
                  pointOfContactID
                }
              }
            }
          }
        }
      }
      nextToken
    }
  }
`;
