import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "@utils/Functions/deepDelete";
import { resources } from "role-utils";

/**
 * Deletes an Assessment resource in the DB with all linked resources.
 * @param {string[]} itemsIDs - array of items IDs to delete
 * @param {boolean} deleteVulnerabilities - if true, will delete all Vulnerabilities associated with this Assessment
 * @param {boolean} deleteTargets - if true, will delete all Targets associated with this Assessment
 * @param {function} onJobStarted - callback function to get a job
 * @returns {Promise<void>}
 * @constructor
 */
export const deleteAssessment = async (
  itemsIDs,
  { deleteVulnerabilities = false, deleteTargets = false },
  onJobStarted,
) => {
  if (!Array.isArray(itemsIDs)) {
    ErrorLogger("[deleteAssessment] Missing Assessment IDs array");
    return;
  }

  return await deepDelete({
    typename: resources.ASSESSMENT,
    itemsIDs,
    config: {
      deleteVulnerabilities,
      deleteTargets,
    },
    onJobStarted,
  });
};
