import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker, DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { useEffect } from "react";

/**
 * @description Configurable DatePicker component (used in Generic Edit Field)
 * @param {string} field - the name of the field being displayed (should be present as a property in item)
 * @param {string} value - the current or default value for the field
 * @param {function} setValue - callback for when data has changed (pass in the current state of the input)
 * @param {object} [inputConfig={}] - holds settings for the date component
 * @param {boolean} [inputConfig.dateOnly=false] - if true, only the date will be displayed (strips time)
 * @param {Date} [inputConfig.minDate] - the minimum date that can be selected
 * @param {Date} [inputConfig.maxDate] - the maximum date that can be selected
 */

export interface InputConfig {
  dateOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

interface UseCustomDateProps {
  field: string;
  value: string | Date | null;
  setValue: (date: Date | null) => void;
  inputConfig?: InputConfig;
}

export const useCustomDate = ({
  value,
  setValue,
  inputConfig = {},
}: UseCustomDateProps): { display: React.ReactNode } => {
  // If a null value is provided, set it to current time
  useEffect(() => {
    if (!value) {
      setValue(new Date());
    }
  }, []);

  // https://mui.com/x/react-date-pickers/date-time-picker/
  const display = (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {inputConfig?.dateOnly === true ? (
        <DesktopDatePicker
          value={value ? new Date(value) : new Date()}
          onChange={setValue}
          minDate={inputConfig?.minDate}
          maxDate={inputConfig?.maxDate}
        />
      ) : (
        <DateTimePicker
          value={value ? new Date(value) : new Date()}
          onChange={setValue}
          minDate={inputConfig?.minDate}
          maxDate={inputConfig?.maxDate}
        />
      )}
    </LocalizationProvider>
  );

  return {
    display,
  };
};
