import { gql } from "@graphql/types";

export const CREATE_CONTROL_FRAMEWORK_DASHBOARD = gql(`
  mutation createControlFrameworkDashboard(
    $name: String!,
    $controlFrameworkId: ID!,
    $description: String
  ) {
    createControlFrameworkDashboard(
      name: $name,
      controlFrameworkId: $controlFrameworkId,
      description: $description
    )
  }
`);
