import { gql } from "@graphql/types";

export const CUSTOM_CONTROL_FRAMEWORK_DASHBOARD = gql(`
  query customControlFrameworkDashboard($dashboardId: ID!) {
    customControlFrameworkDashboard(id: $dashboardId) {
      controlFrameworkID
      name
      controlFramework {
        name
      }
    }
  }
`);
