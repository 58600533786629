import cx from "classnames";
import { ReactElement } from "react";

import Tooltip, { TooltipProps } from "@components/Atoms/Tooltip/Tooltip";
import { getIcon, IconName } from "@components/Ions/icons/components/Icons";

import styles from "./TooltipIcon.module.scss";

export enum TooltipSizeVariant {
  SMALL = "small",
  LARGE = "large",
}

export interface TooltipIconProps extends Omit<TooltipProps, "children"> {
  /** The icon to display. */
  children?: TooltipProps["children"];
  /** The size of the icon (small is 16x16, large is 24x24). */
  size?: TooltipSizeVariant;
  /** If `true`, the tooltip will be displayed in a portal making it appear above other elements. */
  inPortal?: boolean;
}

/** Displays an icon with a tooltip when it is hovered over. */
export const TooltipIcon = ({
  children = getIcon(IconName.INFO),
  size = TooltipSizeVariant.SMALL,
  inPortal = true,
  ...rest
}: TooltipIconProps): ReactElement => {
  // TODO: RISC-7211 Remove this once we have a better solution for z-index issues
  const slotProps = {
    ...rest.slotProps,
    content: {
      ...rest.slotProps?.content,
      style: {
        ...rest.slotProps?.content?.style,
        zIndex: !inPortal ? 8 : undefined,
      },
    },
  };

  return (
    <Tooltip inPortal={inPortal} {...rest} slotProps={slotProps}>
      <button
        className={cx(styles["tooltip-icon"], {
          [styles["tooltip-icon--small"]!]: size === TooltipSizeVariant.SMALL,
          [styles["tooltip-icon--large"]!]: size === TooltipSizeVariant.LARGE,
        })}
      >
        {children}
      </button>
    </Tooltip>
  );
};

export default TooltipIcon;
