import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "@utils/Functions/deepDelete";
import { resources } from "role-utils";

/**
 * Deletes a QuestionnaireAssignee resource in the DB with all linked resources.
 * @param {string[]} itemsIDs - array of items IDs to delete
 * @param {object} config - configuration object
 * @param {function} onJobStarted - callback function to get a job
 * @returns {Promise<void>}
 */
export const deleteQuestionnaireAssignee = async (itemsIDs, config = {}, onJobStarted) => {
  if (!Array.isArray(itemsIDs)) {
    ErrorLogger("[deleteQuestionnaireAssignee] Missing QuestionnaireAssignee IDs array");
    return;
  }

  return await deepDelete({
    typename: resources.QUESTIONNAIRE_ASSIGNEE,
    itemsIDs,
    config,
    onJobStarted,
  });
};
