"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleTableWidget = exports.getIdFromWidgetTag = exports.findWidgetTagInBlock = exports.updateTableOfContents = exports.generateTableOfContents = exports.generateStyles = exports.generateSFDTFromURL = exports.generateSection = exports.generateReportSection = exports.generateReport = exports.generateLists = exports.generateHeadersFooters = exports.generateGeneralTemplate = exports.generateCoverPage = exports.generateAbstractLists = exports.createWidgetTag = exports.createText = exports.createTableRow = exports.createTableCellLine = exports.createTableCell = exports.createTable = exports.createQueryTable = exports.createPieChart = exports.createPageBreak = exports.createList = exports.createInlineText = exports.createImage = exports.createBlankLine = exports.createBarChart = exports.groupTargetVulnerabilityLinkPriority = exports.getWhenSeenVulnerabilityGroup = exports.FIT_TYPE = exports.getImageDimensionsByFitType = exports.generateReportName = exports.REPORT_JOB_TYPE = exports.REPORT_TEMPLATES = exports.COVER_PAGE_IMAGE_STRING = void 0;
var COVER_PAGE_IMAGE_STRING_1 = require("./constants/COVER_PAGE_IMAGE_STRING");
Object.defineProperty(exports, "COVER_PAGE_IMAGE_STRING", { enumerable: true, get: function () { return COVER_PAGE_IMAGE_STRING_1.COVER_PAGE_IMAGE_STRING; } });
var REPORT_TEMPLATES_1 = require("./constants/REPORT_TEMPLATES");
Object.defineProperty(exports, "REPORT_TEMPLATES", { enumerable: true, get: function () { return REPORT_TEMPLATES_1.REPORT_TEMPLATES; } });
var reportJobTypes_1 = require("./constants/reportJobTypes");
Object.defineProperty(exports, "REPORT_JOB_TYPE", { enumerable: true, get: function () { return reportJobTypes_1.REPORT_JOB_TYPE; } });
var generateReportName_1 = require("./functions/generateReportName");
Object.defineProperty(exports, "generateReportName", { enumerable: true, get: function () { return generateReportName_1.generateReportName; } });
var getImageDimensionsByFitType_1 = require("./functions/getImageDimensionsByFitType");
Object.defineProperty(exports, "getImageDimensionsByFitType", { enumerable: true, get: function () { return getImageDimensionsByFitType_1.getImageDimensionsByFitType; } });
var getImageDimensionsByFitType_2 = require("./functions/getImageDimensionsByFitType");
Object.defineProperty(exports, "FIT_TYPE", { enumerable: true, get: function () { return getImageDimensionsByFitType_2.FIT_TYPE; } });
var getWhenSeenVulnerabilityGroup_1 = require("./functions/getWhenSeenVulnerabilityGroup");
Object.defineProperty(exports, "getWhenSeenVulnerabilityGroup", { enumerable: true, get: function () { return getWhenSeenVulnerabilityGroup_1.getWhenSeenVulnerabilityGroup; } });
var groupTargetVulnerabilityLinkPriority_1 = require("./functions/groupTargetVulnerabilityLinkPriority");
Object.defineProperty(exports, "groupTargetVulnerabilityLinkPriority", { enumerable: true, get: function () { return groupTargetVulnerabilityLinkPriority_1.groupTargetVulnerabilityLinkPriority; } });
var createBarChart_1 = require("./sfdt/createBarChart");
Object.defineProperty(exports, "createBarChart", { enumerable: true, get: function () { return createBarChart_1.createBarChart; } });
var createBlankLine_1 = require("./sfdt/createBlankLine");
Object.defineProperty(exports, "createBlankLine", { enumerable: true, get: function () { return createBlankLine_1.createBlankLine; } });
var createImage_1 = require("./sfdt/createImage");
Object.defineProperty(exports, "createImage", { enumerable: true, get: function () { return createImage_1.createImage; } });
var createInlineText_1 = require("./sfdt/createInlineText");
Object.defineProperty(exports, "createInlineText", { enumerable: true, get: function () { return createInlineText_1.createInlineText; } });
var createList_1 = require("./sfdt/createList");
Object.defineProperty(exports, "createList", { enumerable: true, get: function () { return createList_1.createList; } });
var createPageBreak_1 = require("./sfdt/createPageBreak");
Object.defineProperty(exports, "createPageBreak", { enumerable: true, get: function () { return createPageBreak_1.createPageBreak; } });
var createPieChart_1 = require("./sfdt/createPieChart");
Object.defineProperty(exports, "createPieChart", { enumerable: true, get: function () { return createPieChart_1.createPieChart; } });
var createQueryTable_1 = require("./sfdt/createQueryTable");
Object.defineProperty(exports, "createQueryTable", { enumerable: true, get: function () { return createQueryTable_1.createQueryTable; } });
var createTable_1 = require("./sfdt/createTable");
Object.defineProperty(exports, "createTable", { enumerable: true, get: function () { return createTable_1.createTable; } });
var createTableCell_1 = require("./sfdt/createTableCell");
Object.defineProperty(exports, "createTableCell", { enumerable: true, get: function () { return createTableCell_1.createTableCell; } });
var createTableCellLine_1 = require("./sfdt/createTableCellLine");
Object.defineProperty(exports, "createTableCellLine", { enumerable: true, get: function () { return createTableCellLine_1.createTableCellLine; } });
var createTableRow_1 = require("./sfdt/createTableRow");
Object.defineProperty(exports, "createTableRow", { enumerable: true, get: function () { return createTableRow_1.createTableRow; } });
var createText_1 = require("./sfdt/createText");
Object.defineProperty(exports, "createText", { enumerable: true, get: function () { return createText_1.createText; } });
var createWidgetTag_1 = require("./sfdt/createWidgetTag");
Object.defineProperty(exports, "createWidgetTag", { enumerable: true, get: function () { return createWidgetTag_1.createWidgetTag; } });
var generateAbstractLists_1 = require("./sfdt/generateAbstractLists");
Object.defineProperty(exports, "generateAbstractLists", { enumerable: true, get: function () { return generateAbstractLists_1.generateAbstractLists; } });
var generateCoverPage_1 = require("./sfdt/generateCoverPage");
Object.defineProperty(exports, "generateCoverPage", { enumerable: true, get: function () { return generateCoverPage_1.generateCoverPage; } });
var generateGeneralTemplate_1 = require("./sfdt/generateGeneralTemplate");
Object.defineProperty(exports, "generateGeneralTemplate", { enumerable: true, get: function () { return generateGeneralTemplate_1.generateGeneralTemplate; } });
var generateHeadersFooters_1 = require("./sfdt/generateHeadersFooters");
Object.defineProperty(exports, "generateHeadersFooters", { enumerable: true, get: function () { return generateHeadersFooters_1.generateHeadersFooters; } });
var generateLists_1 = require("./sfdt/generateLists");
Object.defineProperty(exports, "generateLists", { enumerable: true, get: function () { return generateLists_1.generateLists; } });
var generateReport_1 = require("./sfdt/generateReport");
Object.defineProperty(exports, "generateReport", { enumerable: true, get: function () { return generateReport_1.generateReport; } });
var generateReportSection_1 = require("./sfdt/generateReportSection");
Object.defineProperty(exports, "generateReportSection", { enumerable: true, get: function () { return generateReportSection_1.generateReportSection; } });
var generateSection_1 = require("./sfdt/generateSection");
Object.defineProperty(exports, "generateSection", { enumerable: true, get: function () { return generateSection_1.generateSection; } });
var generateSFDTFromURL_1 = require("./sfdt/generateSFDTFromURL");
Object.defineProperty(exports, "generateSFDTFromURL", { enumerable: true, get: function () { return generateSFDTFromURL_1.generateSFDTFromURL; } });
var generateStyles_1 = require("./sfdt/generateStyles");
Object.defineProperty(exports, "generateStyles", { enumerable: true, get: function () { return generateStyles_1.generateStyles; } });
var generateTableOfContents_1 = require("./sfdt/tableOfContents/functions/generateTableOfContents");
Object.defineProperty(exports, "generateTableOfContents", { enumerable: true, get: function () { return generateTableOfContents_1.generateTableOfContents; } });
var updateTableOfContents_1 = require("./sfdt/tableOfContents/functions/updateTableOfContents");
Object.defineProperty(exports, "updateTableOfContents", { enumerable: true, get: function () { return updateTableOfContents_1.updateTableOfContents; } });
var findWidgetTagInBlock_1 = require("./sfdt/widgets/findWidgetTagInBlock");
Object.defineProperty(exports, "findWidgetTagInBlock", { enumerable: true, get: function () { return findWidgetTagInBlock_1.findWidgetTagInBlock; } });
var getIdFromWidgetTag_1 = require("./sfdt/widgets/getIdFromWidgetTag");
Object.defineProperty(exports, "getIdFromWidgetTag", { enumerable: true, get: function () { return getIdFromWidgetTag_1.getIdFromWidgetTag; } });
var handleTableWidget_1 = require("./sfdt/widgets/handleTableWidget");
Object.defineProperty(exports, "handleTableWidget", { enumerable: true, get: function () { return handleTableWidget_1.handleTableWidget; } });
