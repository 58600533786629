import pluralize from "pluralize";

import { createJob } from "@views/OrganizationManager/Jobs/functions/createJob";

import { ErrorLogger } from "../EventLogger";

import { fargateApi } from "./FargateApi/fargateApi";

/**
 * Generic deep delete function
 * @param {string[]} itemsIDs - array of items IDs to delete
 * @param {string} typename - typename to delete
 * @param {object} config - config for the delete
 * @param {string} organizationID - organization id
 * @param {string} userEmail - user email
 * @param {string} sentryTrace - sentry trace
 * @param {function} onJobStarted - callback function to get job
 * @param {boolean} useQueue - whather to use a queue system or not (default: true)
 * @returns {Promise<null|Array|*>}
 */
export const deepDelete = async ({
  itemsIDs,
  typename,
  config = {},
  organizationID,
  userEmail,
  sentryTrace,
  onJobStarted,
  useQueue = true,
}) => {
  if (!Array.isArray(itemsIDs)) {
    ErrorLogger("Error! Invalid input for deepDelete. Missing itemsIDs array.");
    return;
  }

  if (!typename) {
    ErrorLogger("Error! Invalid input for deepDelete. Missing resource name.");
    return;
  }

  let job;

  if (organizationID) {
    const byUser = userEmail ? ` by: ${userEmail}` : "";
    job = await createJob({
      name: `Delete array of ${pluralize(typename)}`,
      logs: JSON.stringify([
        {
          type: "info",
          title: `Delete operation started ${byUser}`,
          timestamp: new Date().toISOString(),
        },
      ]),
      status: "started",
      ownerGroup: organizationID,
    });

    if (typeof onJobStarted === "function") {
      await onJobStarted({ job });
    }
  }

  return await fargateApi({
    input: {
      route: `delete/${typename}`,
      itemsIDs,
      config,
      job,
      sentryTrace,
      typename,
      organizationID,
      useQueue,
    },
  });
};
