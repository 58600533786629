import React from "react";

import { withOrganizationCheck } from "@utils/Context/withOrganizationCheck";

/**
 * Displays timeline information when evidence is created
 * @returns {JSX.Element}
 */
const CreatedAtActivity = ({ id }) => {
  return (
    <span id={`createdat_activity_${id}`} style={{ fontSize: ".9em" }}>
      Evidence Created
    </span>
  );
};

export default withOrganizationCheck(CreatedAtActivity);
