import React, { ReactElement, useEffect } from "react";
import { Button, ButtonGroup, Card, CardBody, CardHeader, NavLink, Spinner } from "reactstrap";

import { convertCamelCaseToSentence } from "@rivial-security/func-utils";

import { routeType } from "../../definitions/constants/routeTypes";
import { getResourceRoute } from "../../definitions/functions/getResourceRoute";
import { DontHavePermission } from "../../utils/AccessControl/components/DontHavePermission";
import ItemCheckOverlay from "../../utils/Overlays/ItemCheckOverlay";
import { useQueryGetItem } from "../graphql/useQueryGetItem";
import { useCheckPermissions } from "../permissions/useCheckPermissions/useCheckPermissions";

import { DetailsTable } from "./useDetails/hooks/useDetails";

/**
 * Hook allowing to show a table of fields where the first column is the field name and the second is editable content for the field
 * @param {string} organizationID - the currently selected organization id
 * @param {string} module - platform module to which the displayed information pertains to
 * @param {string} resource - the resource inside the provided module that this information pertains to
 * @param {boolean} disableRoleChecking - if TRUE will not perform permission checking based on user role
 *
 * @param {object} queryConfig - config object for useQueryGetItem that this utility uses to retrieve data
 * @param {object} detailsConfig - data configuration for the details UI
 * @param {object} config - visual configurations for the details UI
 * @param {string[]} fields - the fields to query, used in DetailsTable
 * @param {string[]} displayFields - optinal subset of 'fields' to display in the details hook
 * @param {object[]}    [customFields]            - additional configurations for each field (if needed)
 * @param {string}      customFields[].field      - name of the field (used for the label)
 * @param {JSX.Element} customFields[].component  - the custom to component to display to the right of the label
 * @param {boolean}     customFields[].isComputed - if TRUE will be displayed even if it is not in the query
 * @param {string}      customFields[].tooltip    - the tooltip text to display to the right of the field component
 * @param {string}      customFields[].inputType  - matches GENERIC_FIELD_TYPES enums
 * @param {function} updateMutationFunction       - executed before each mutation has to accept the following params ->
 * ({input, fieldName, fieldIndex}) and return the input to use in the mutation
 * @param updateMutation - the graphql mutation used to update item info in the database
 * @param fieldNameDictionary - a map of field names to friendly display names show to the user
 * @param {string} typename - the schema type of the item being displayed
 * @param {object} nestedFields - connections and nested items to retrieve as part of the item query
 * @param {boolean} tableDisplay - if TRUE display will only contain the key value pairs in a table, no header or card
 * @param {function} [updateItemById] - a callback to send back updated data in the event of an update, used by grids
 * @param {function} setIsLoading - external loading state setter
 * @param {function} setQueryRef - a reference to the query hook
 * @param {string[]} [requiredFields] - list of fields that would make up a complete item (an alert is displayed if any of these fields are missing)
 * @returns {{isLoading: boolean, setInputId: (value: (((prevState: *) => *) | *)) => void, inputId: *, item: unknown, setIsLoading: (value: (((prevState: boolean) => boolean) | boolean)) => void, tableDisplay: (ReactElement | React.DetailedReactHTMLElement<{item: unknown, resetFunction: ((function(): void)|*), updateItemById: updateItemById, key: string}, HTMLElement>|JSX.Element), resetFunction: ((function(): void)|*), display: (ReactElement | React.DetailedReactHTMLElement<{item: unknown, resetFunction: ((function(): void)|*), updateItemById: updateItemById, key: string}, HTMLElement>|JSX.Element), resetIndex: number, reset: function(): void, setItem: (value: unknown) => void}}
 */
export const useDetailsCard = ({
  organizationID,
  module,
  resource,
  disableRoleChecking,

  queryConfig = {},
  detailsConfig = {}, // deprecated. customFields, updateMutation, and fieldNameDictionary should be passed directly
  config = {},
  fields,
  displayFields,
  customFields: customFieldsInput,
  updateMutationFunction,
  updateMutation: updateMutationInput,
  fieldNameDictionary: fieldNameDictionaryInput,
  typename,
  nestedFields,
  tableDisplay: showTableDisplay,
  updateItemById: updateItemByIdFunc,
  setIsLoading,
  setQueryRef,
  requiredFields = [],
}) => {
  /**
   * For backwards compatibility with the 'detailsConfig' object
   */
  const customFields = customFieldsInput || detailsConfig?.customFields;
  const updateMutation = updateMutationInput || detailsConfig?.updateMutation;
  const fieldNameDictionary = fieldNameDictionaryInput || detailsConfig?.fieldNameDictionary;

  const {
    header = typename ? `${convertCamelCaseToSentence(typename)} Details` : "Details",
    headerButtons,
    route,
    disableRoute,
    enableEdits = false,
    detailsComponent,
    enableNotes = false,
    observationConnectionField,
    otherTableRows,
    appendComponent,
  } = config;

  const checkPermissionsHook = useCheckPermissions({
    module,
    resource,
    disableRoleChecking,
  });

  const queryHook = useQueryGetItem({
    ...queryConfig,
    disableRoleChecking,
    module,
    resource,
    fields,
    typename,
    nestedFields,
    organizationID,
  });

  //Check if route is available in definition files
  const definitionRoute = getResourceRoute({
    item: queryHook?.item,
    override: route,
    routeType: routeType.DETAILS,
    typename,
    prefix: "#/",
  });

  /**
   * Calls any external 'setIsLoading' functions when this queryHook updates loading
   */
  useEffect(() => {
    setIsLoading?.(queryHook.isLoading);
  }, [queryHook.isLoading]);

  /**
   * Sets any external queryHook refs
   */
  useEffect(() => {
    setQueryRef?.(queryHook);
  }, []);

  const updateItemById = (input) => {
    queryHook.setItem((item) => {
      return {
        ...item,
        ...input,
      };
    });
    updateItemByIdFunc?.(input);
  };

  const tableDisplay = (
    <ItemCheckOverlay
      item={queryHook?.item}
      isLoading={queryHook?.isLoading}
      resource={resource}
      requiredFields={requiredFields}
    >
      {detailsComponent ? (
        React.cloneElement(detailsComponent, {
          item: queryHook.item,
          resetFunction: queryHook.reset,
          key: JSON.stringify(queryHook.item),
          updateItemById,
        })
      ) : (
        <DetailsTable
          typename={typename}
          item={queryHook.item}
          fields={displayFields || fields || (detailsConfig && detailsConfig.fields)}
          mutation={updateMutation}
          customFields={customFields}
          fieldNameDictionary={fieldNameDictionary}
          module={module}
          resource={resource}
          disableRoleChecking={disableRoleChecking}
          enableNotes={enableNotes}
          observationConnectionField={observationConnectionField}
          otherTableRows={otherTableRows}
          appendComponent={appendComponent}
          resetFunction={queryHook.reset}
          updateItemById={updateItemById}
          updateMutationFunction={updateMutationFunction}
        />
      )}
    </ItemCheckOverlay>
  );

  const display =
    checkPermissionsHook.module.isEnabled && checkPermissionsHook.resource.read ? (
      <Card>
        <CardHeader>
          {queryHook.isLoading && <Spinner style={{ marginRight: "0.5rem" }} size="sm" color="primary" />}
          {header}
          {headerButtons && headerButtons.length > 0 && (
            <ButtonGroup className="float-right">
              {headerButtons.map((button, index) =>
                React.cloneElement(button, {
                  key: index,
                  item: queryHook.item,
                  resetFunction: queryHook.reset,
                }),
              )}
            </ButtonGroup>
          )}
          {queryHook.reset && (
            <Button
              size="sm"
              color="ghost-secondary"
              className="btn-pill float-sm-right"
              onClick={() => {
                queryHook.reset?.();
              }}
              title="Refresh Card Data"
            >
              <i className="icon-reload" />
            </Button>
          )}
          {!disableRoute && definitionRoute && (
            <NavLink
              href={`${definitionRoute}${queryHook.item && queryHook.item.id}`}
              className="icon-share-alt"
              style={{ marginLeft: "1em", display: "inline" }}
              title="Go to Details Page"
            />
          )}
        </CardHeader>
        <CardBody>{tableDisplay}</CardBody>
      </Card>
    ) : (
      <DontHavePermission module={module} resource={resource} />
    );

  return {
    ...queryHook,
    display: showTableDisplay ? tableDisplay : display,
    item: queryHook.item,
    setItem: queryHook.setItem,
    isLoading: queryHook.isLoading,
    setIsLoading: queryHook.setIsLoading,
    inputId: queryHook.inputId,
    setInputId: queryHook.setInputId,
    tableDisplay,
    resetFunction: queryHook.reset,
    resetIndex: queryHook.resetIndex,
  };
};

/**
 * @typedef {Object} DetailsField - the custom fields object structure
 * @property {string} field - the name of the field that the rest of the properties relate to
 * @property {JSX.Element} component - the component to render for this field
 * @property {boolean} isHidden - if TRUE the field will not be shown to the user
 * @property {string} tooltip - a helpful message to display as a icon with a hover effe
 */
