/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query complianceTrendCoordinates($controlFrameworkId: ID!, $nextToken: String, $limit: Int, $dateRange: DateRange, $sort: SortDirection) {\n    controlFramework(id: $controlFrameworkId) {\n      complianceTrendCoordinates(nextToken: $nextToken, limit: $limit, dateRange: $dateRange, sort: $sort) {\n        nextToken\n        items {\n          x\n          y\n        }\n      }\n    }\n  }\n": types.ComplianceTrendCoordinatesDocument,
    "\n  query controlFrameworkDashboards($limit: Int, $nextToken: String, $sort: CustomDashboardSort) {\n    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken, sort: $sort) {\n      items {\n        id\n        name\n        description\n        controlFramework {\n          id\n          name\n          customName\n          dashboardLogo\n          controlStatistics {\n            countInPlace\n            countControls\n            compliancePercentage\n          }\n        }\n      }\n      nextToken\n    }\n  }\n": types.ControlFrameworkDashboardsDocument,
    "\n  query controlsForControlFramework(\n    $frameworkID: ID!,\n    $controlsSort: ControlSort\n  ) {\n    controlFramework(id: $frameworkID) {\n      id\n      controls(sort: $controlsSort) {\n        items {\n          id\n          name\n          statementNumber\n          inPlace\n          evidences {\n            id\n            name\n            ownerGroup\n          }\n          tags {\n            id\n            name\n            ownerGroup\n          }\n          ownerGroup\n        }\n      }\n      ownerGroup\n    }\n  }\n": types.ControlsForControlFrameworkDocument,
    "\n  mutation createControlFrameworkDashboard(\n    $name: String!,\n    $controlFrameworkId: ID!,\n    $description: String\n  ) {\n    createControlFrameworkDashboard(\n      name: $name,\n      controlFrameworkId: $controlFrameworkId,\n      description: $description\n    )\n  }\n": types.CreateControlFrameworkDashboardDocument,
    "\n  query customControlFrameworkDashboard($dashboardId: ID!) {\n    customControlFrameworkDashboard(id: $dashboardId) {\n      controlFrameworkID\n      name\n      controlFramework {\n        name\n      }\n    }\n  }\n": types.CustomControlFrameworkDashboardDocument,
    "\n  mutation DeleteControlFrameworkDashboard($id: ID!) {\n    deleteControlFrameworkDashboard(id: $id)\n  }\n": types.DeleteControlFrameworkDashboardDocument,
    "\n  query evidencesForControlFramework(\n    $frameworkId: ID!,\n    $filter: EvidenceFilter,\n    $evidencesSort: EvidenceSort,\n    $pointsOfContactSort: PointOfContactSort,\n    $tagsSort: TagSort\n  ) {\n    evidencesForControlFramework(frameworkID: $frameworkId, filter: $filter, sort: $evidencesSort) {\n      id\n      name\n      status\n      type\n      frequency\n      pointsOfContact(sort: $pointsOfContactSort) {\n        id\n        fullName\n      }\n      tags(sort: $tagsSort) {\n        id\n        name\n        backgroundColor\n        description\n      }\n    }\n  }\n": types.EvidencesForControlFrameworkDocument,
    "\n   query complianceStatistics($dashboardID: ID) {\n    complianceStatistics(dashboardID: $dashboardID) {\n      countAudits\n      countArtifacts\n      countEvidencesByStatus {\n        expired\n        pendingValidation\n        expiringSoon\n        inPlace\n      }\n      countFrameworksAdded\n      controlStatistics {\n        countInPlace\n        countNotInPlace\n        countControls\n        compliancePercentage\n      }\n    }\n  }\n": types.ComplianceStatisticsDocument,
    "\n  mutation updateControlFrameworkDashboard($id: ID!, $name: String!, $description: String) {\n    updateControlFrameworkDashboard(id: $id, name: $name, description: $description)\n  }\n": types.UpdateControlFrameworkDashboardDocument,
    "\n  query userCreateableFrameworkTemplates {\n    userCreateableFrameworkTemplates {\n      id\n      name\n    }\n  }\n": types.UserCreateableFrameworkTemplatesDocument,
    "\n  mutation createEvidence(\n    $name: String!,\n    $enabled: Boolean!,\n    $status: EvidenceStatus!,\n    $type: EvidenceType!,\n    $itemNumber: String!,\n    $task: String,\n    $pointOfContactIDs: [ID!],\n    $frequency: String,\n    $allowUnauthenticatedSubmissions: Boolean!\n  ) {\n    createEvidence(\n      name: $name,\n      enabled: $enabled,\n      status: $status,\n      type: $type,\n      itemNumber: $itemNumber,\n      task: $task,\n      pointOfContactIDs: $pointOfContactIDs,\n      frequency: $frequency,\n      allowUnauthenticatedSubmissions: $allowUnauthenticatedSubmissions\n    )\n  }\n": types.CreateEvidenceDocument,
    "\nquery evidenceActivitiesForControlFramework($controlFrameworkId: ID!, $dateRange: DateRange) {\n  controlFramework(id: $controlFrameworkId) {\n    evidenceActivities(dateRange: $dateRange) {\n      items {\n        attested\n        validatedBy\n        comment\n        data\n        date\n        evidenceID\n        id\n        type\n        pointOfContactID\n        ownerGroup\n        pointOfContact {\n          fullName\n          id\n        }\n        evidence {\n          ownerGroup\n          name\n          createdAt\n        }\n      }\n    }\n  }\n}\n": types.EvidenceActivitiesForControlFrameworkDocument,
    "\n  query evidencesForOrganization($evidenceSort: EvidenceSort, $tagSort: TagSort, $pointOfContactSort: PointOfContactSort) {\n    evidencesForOrganization(sort: $evidenceSort) {\n      items {\n        id\n        name\n        status\n        type\n        frequency\n        tags(sort: $tagSort) {\n          id\n          name\n          backgroundColor\n          description\n        }\n        pointsOfContact(sort: $pointOfContactSort) {\n          id\n          fullName\n        }\n      }\n    }\n  }\n": types.EvidencesForOrganizationDocument,
    "\n  query pointsOfContact($sort: PointOfContactSort, $limit: Int, $nextToken: String) {\n    pointsOfContact(sort: $sort, limit: $limit, nextToken: $nextToken) {\n      items {\n        id\n        fullName\n      }\n    }\n  }\n": types.PointsOfContactDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query complianceTrendCoordinates($controlFrameworkId: ID!, $nextToken: String, $limit: Int, $dateRange: DateRange, $sort: SortDirection) {\n    controlFramework(id: $controlFrameworkId) {\n      complianceTrendCoordinates(nextToken: $nextToken, limit: $limit, dateRange: $dateRange, sort: $sort) {\n        nextToken\n        items {\n          x\n          y\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query complianceTrendCoordinates($controlFrameworkId: ID!, $nextToken: String, $limit: Int, $dateRange: DateRange, $sort: SortDirection) {\n    controlFramework(id: $controlFrameworkId) {\n      complianceTrendCoordinates(nextToken: $nextToken, limit: $limit, dateRange: $dateRange, sort: $sort) {\n        nextToken\n        items {\n          x\n          y\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query controlFrameworkDashboards($limit: Int, $nextToken: String, $sort: CustomDashboardSort) {\n    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken, sort: $sort) {\n      items {\n        id\n        name\n        description\n        controlFramework {\n          id\n          name\n          customName\n          dashboardLogo\n          controlStatistics {\n            countInPlace\n            countControls\n            compliancePercentage\n          }\n        }\n      }\n      nextToken\n    }\n  }\n"): (typeof documents)["\n  query controlFrameworkDashboards($limit: Int, $nextToken: String, $sort: CustomDashboardSort) {\n    controlFrameworkDashboards(limit: $limit, nextToken: $nextToken, sort: $sort) {\n      items {\n        id\n        name\n        description\n        controlFramework {\n          id\n          name\n          customName\n          dashboardLogo\n          controlStatistics {\n            countInPlace\n            countControls\n            compliancePercentage\n          }\n        }\n      }\n      nextToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query controlsForControlFramework(\n    $frameworkID: ID!,\n    $controlsSort: ControlSort\n  ) {\n    controlFramework(id: $frameworkID) {\n      id\n      controls(sort: $controlsSort) {\n        items {\n          id\n          name\n          statementNumber\n          inPlace\n          evidences {\n            id\n            name\n            ownerGroup\n          }\n          tags {\n            id\n            name\n            ownerGroup\n          }\n          ownerGroup\n        }\n      }\n      ownerGroup\n    }\n  }\n"): (typeof documents)["\n  query controlsForControlFramework(\n    $frameworkID: ID!,\n    $controlsSort: ControlSort\n  ) {\n    controlFramework(id: $frameworkID) {\n      id\n      controls(sort: $controlsSort) {\n        items {\n          id\n          name\n          statementNumber\n          inPlace\n          evidences {\n            id\n            name\n            ownerGroup\n          }\n          tags {\n            id\n            name\n            ownerGroup\n          }\n          ownerGroup\n        }\n      }\n      ownerGroup\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createControlFrameworkDashboard(\n    $name: String!,\n    $controlFrameworkId: ID!,\n    $description: String\n  ) {\n    createControlFrameworkDashboard(\n      name: $name,\n      controlFrameworkId: $controlFrameworkId,\n      description: $description\n    )\n  }\n"): (typeof documents)["\n  mutation createControlFrameworkDashboard(\n    $name: String!,\n    $controlFrameworkId: ID!,\n    $description: String\n  ) {\n    createControlFrameworkDashboard(\n      name: $name,\n      controlFrameworkId: $controlFrameworkId,\n      description: $description\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query customControlFrameworkDashboard($dashboardId: ID!) {\n    customControlFrameworkDashboard(id: $dashboardId) {\n      controlFrameworkID\n      name\n      controlFramework {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query customControlFrameworkDashboard($dashboardId: ID!) {\n    customControlFrameworkDashboard(id: $dashboardId) {\n      controlFrameworkID\n      name\n      controlFramework {\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation DeleteControlFrameworkDashboard($id: ID!) {\n    deleteControlFrameworkDashboard(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteControlFrameworkDashboard($id: ID!) {\n    deleteControlFrameworkDashboard(id: $id)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query evidencesForControlFramework(\n    $frameworkId: ID!,\n    $filter: EvidenceFilter,\n    $evidencesSort: EvidenceSort,\n    $pointsOfContactSort: PointOfContactSort,\n    $tagsSort: TagSort\n  ) {\n    evidencesForControlFramework(frameworkID: $frameworkId, filter: $filter, sort: $evidencesSort) {\n      id\n      name\n      status\n      type\n      frequency\n      pointsOfContact(sort: $pointsOfContactSort) {\n        id\n        fullName\n      }\n      tags(sort: $tagsSort) {\n        id\n        name\n        backgroundColor\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  query evidencesForControlFramework(\n    $frameworkId: ID!,\n    $filter: EvidenceFilter,\n    $evidencesSort: EvidenceSort,\n    $pointsOfContactSort: PointOfContactSort,\n    $tagsSort: TagSort\n  ) {\n    evidencesForControlFramework(frameworkID: $frameworkId, filter: $filter, sort: $evidencesSort) {\n      id\n      name\n      status\n      type\n      frequency\n      pointsOfContact(sort: $pointsOfContactSort) {\n        id\n        fullName\n      }\n      tags(sort: $tagsSort) {\n        id\n        name\n        backgroundColor\n        description\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n   query complianceStatistics($dashboardID: ID) {\n    complianceStatistics(dashboardID: $dashboardID) {\n      countAudits\n      countArtifacts\n      countEvidencesByStatus {\n        expired\n        pendingValidation\n        expiringSoon\n        inPlace\n      }\n      countFrameworksAdded\n      controlStatistics {\n        countInPlace\n        countNotInPlace\n        countControls\n        compliancePercentage\n      }\n    }\n  }\n"): (typeof documents)["\n   query complianceStatistics($dashboardID: ID) {\n    complianceStatistics(dashboardID: $dashboardID) {\n      countAudits\n      countArtifacts\n      countEvidencesByStatus {\n        expired\n        pendingValidation\n        expiringSoon\n        inPlace\n      }\n      countFrameworksAdded\n      controlStatistics {\n        countInPlace\n        countNotInPlace\n        countControls\n        compliancePercentage\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateControlFrameworkDashboard($id: ID!, $name: String!, $description: String) {\n    updateControlFrameworkDashboard(id: $id, name: $name, description: $description)\n  }\n"): (typeof documents)["\n  mutation updateControlFrameworkDashboard($id: ID!, $name: String!, $description: String) {\n    updateControlFrameworkDashboard(id: $id, name: $name, description: $description)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userCreateableFrameworkTemplates {\n    userCreateableFrameworkTemplates {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query userCreateableFrameworkTemplates {\n    userCreateableFrameworkTemplates {\n      id\n      name\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createEvidence(\n    $name: String!,\n    $enabled: Boolean!,\n    $status: EvidenceStatus!,\n    $type: EvidenceType!,\n    $itemNumber: String!,\n    $task: String,\n    $pointOfContactIDs: [ID!],\n    $frequency: String,\n    $allowUnauthenticatedSubmissions: Boolean!\n  ) {\n    createEvidence(\n      name: $name,\n      enabled: $enabled,\n      status: $status,\n      type: $type,\n      itemNumber: $itemNumber,\n      task: $task,\n      pointOfContactIDs: $pointOfContactIDs,\n      frequency: $frequency,\n      allowUnauthenticatedSubmissions: $allowUnauthenticatedSubmissions\n    )\n  }\n"): (typeof documents)["\n  mutation createEvidence(\n    $name: String!,\n    $enabled: Boolean!,\n    $status: EvidenceStatus!,\n    $type: EvidenceType!,\n    $itemNumber: String!,\n    $task: String,\n    $pointOfContactIDs: [ID!],\n    $frequency: String,\n    $allowUnauthenticatedSubmissions: Boolean!\n  ) {\n    createEvidence(\n      name: $name,\n      enabled: $enabled,\n      status: $status,\n      type: $type,\n      itemNumber: $itemNumber,\n      task: $task,\n      pointOfContactIDs: $pointOfContactIDs,\n      frequency: $frequency,\n      allowUnauthenticatedSubmissions: $allowUnauthenticatedSubmissions\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery evidenceActivitiesForControlFramework($controlFrameworkId: ID!, $dateRange: DateRange) {\n  controlFramework(id: $controlFrameworkId) {\n    evidenceActivities(dateRange: $dateRange) {\n      items {\n        attested\n        validatedBy\n        comment\n        data\n        date\n        evidenceID\n        id\n        type\n        pointOfContactID\n        ownerGroup\n        pointOfContact {\n          fullName\n          id\n        }\n        evidence {\n          ownerGroup\n          name\n          createdAt\n        }\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery evidenceActivitiesForControlFramework($controlFrameworkId: ID!, $dateRange: DateRange) {\n  controlFramework(id: $controlFrameworkId) {\n    evidenceActivities(dateRange: $dateRange) {\n      items {\n        attested\n        validatedBy\n        comment\n        data\n        date\n        evidenceID\n        id\n        type\n        pointOfContactID\n        ownerGroup\n        pointOfContact {\n          fullName\n          id\n        }\n        evidence {\n          ownerGroup\n          name\n          createdAt\n        }\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query evidencesForOrganization($evidenceSort: EvidenceSort, $tagSort: TagSort, $pointOfContactSort: PointOfContactSort) {\n    evidencesForOrganization(sort: $evidenceSort) {\n      items {\n        id\n        name\n        status\n        type\n        frequency\n        tags(sort: $tagSort) {\n          id\n          name\n          backgroundColor\n          description\n        }\n        pointsOfContact(sort: $pointOfContactSort) {\n          id\n          fullName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query evidencesForOrganization($evidenceSort: EvidenceSort, $tagSort: TagSort, $pointOfContactSort: PointOfContactSort) {\n    evidencesForOrganization(sort: $evidenceSort) {\n      items {\n        id\n        name\n        status\n        type\n        frequency\n        tags(sort: $tagSort) {\n          id\n          name\n          backgroundColor\n          description\n        }\n        pointsOfContact(sort: $pointOfContactSort) {\n          id\n          fullName\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query pointsOfContact($sort: PointOfContactSort, $limit: Int, $nextToken: String) {\n    pointsOfContact(sort: $sort, limit: $limit, nextToken: $nextToken) {\n      items {\n        id\n        fullName\n      }\n    }\n  }\n"): (typeof documents)["\n  query pointsOfContact($sort: PointOfContactSort, $limit: Int, $nextToken: String) {\n    pointsOfContact(sort: $sort, limit: $limit, nextToken: $nextToken) {\n      items {\n        id\n        fullName\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;