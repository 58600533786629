import { Storage } from "@aws-amplify/storage";
import { Alert } from "@mui/material";
import List from "@mui/material/List";
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";

import {
  checkAutomationStepsAreValid,
  getAutomationStepsData,
  sortAutomationStepsByExecutionOrder,
} from "@rivial-security/automation-utils";
import { isNullOrUndefined } from "@rivial-security/func-utils";
import { generateGraphql } from "@rivial-security/generategraphql";

import { ErrorLogger } from "@utils/EventLogger";

import { ItemMutation } from "../../../../utils/Functions/Graphql/ItemMutation";
import Loader from "../../../../utils/LoadingComponents/Loader";
import SmartValueContext from "../../../../utils/SmartValues/context/SmartValueContext";
import { AUTOMATION_TYPES_CONFIG } from "../enums/AUTOMATION_TYPES";
import { formatAutomationStepForMutation } from "../functions/formatAutomationStepForMutation";
import { useAutomationSmartValues } from "../hooks/useAutomationSmartValues";

import AutomationListItem from "./AutomationListItem";
import SelectAutomationModal from "./SelectAutomationModal";

/**
 * Custom UI for configuring the list of Automation Steps
 * @param {string} organizationID - the currently selected organization
 * @param {Automation} item - an existing Automation resource, to be edited using this UI
 * @param {object} automation - the parent automation item itself, with trigger and condition info
 * @param {boolean} disableEdits - whether to disable the form fields
 * @param {boolean} isTemplate - whether the form is being used to create a template
 * @param {function} onChangeCallback - function to call when the automation configuration has changed will only be called when performUpdates is FALSE
 * @param {object} input - input from a useForm hook in-case th e component is used as a custom field
 * @param {boolean} performUpdates - if TRUE will send all automation updates to the backend (needs item to be passed in)
 * @param {string[]} disabledStepTypes - array of step types that should be disabled in the UI to create new steps
 * @param {object[]} ancestorSteps - step configurations from parent nodes (for instance, the siblings of loop steps)
 * @param {boolean} [showLoopSmartValues = false] - if TRUE will show the smart values related to loop. gets placed into SmartValueContext here to be accessed downstream
 * @returns {JSX.Element}
 */
const ConfigureAutomation = ({
  organizationID,

  // when coming from the details page, the Automation item is passed in directly.
  // when this component is used as nested field, this 'item' is the Automation step itself,
  // and the 'automation' is the parent Automation object.
  item,

  // gets passed in when this component is used as a nested automation step
  // defaults to 'item' if not passed in, assuming this component is being used as a top-level form
  automation = item,

  disableEdits,
  isTemplate = false,
  onChangeCallback,
  input,
  performUpdates = false,
  disabledStepTypes,
  ancestorSteps,
  showLoopSmartValues = false,
}) => {
  /**
   * Holds the state for the list of Automations. This automatically gets passed up to the form
   */
  const [automations, setAutomations] = useState([]);
  const [sortedAutomations, setSortedAutomations] = useState([]);
  const [automationStepCheckResults, setAutomationStepCheckResults] = useState([]);
  const [stepsLoading, setStepsLoading] = useState(false);
  const [downloadedTemplates, setDownloadedTemplates] = useState({});

  // If this is being used for a details or create page for an automation, pre-fill UI
  useEffect(() => {
    // Prioritize grabbing information from the item prop, then the input prop
    const automationItem = item || input;
    if (!isNullOrUndefined(automationItem)) {
      let newAutomations = automationItem?.automations;
      if (Array.isArray(newAutomations)) {
        newAutomations = newAutomations.map((automation) => {
          let config;
          if (typeof automation?.config === "string") {
            config = JSON.parse(automation?.config);
          } else {
            config = automation?.config || {};
          }
          return {
            ...automation,
            config,
          };
        });

        if (JSON.stringify(newAutomations) !== JSON.stringify(automations)) {
          setAutomations(newAutomations);
        }
      }
    }
  }, [item, input]);

  useEffect(() => {
    const getData = async () => {
      setStepsLoading(true);

      if (Array.isArray(automations) && automations.length === 0) {
        setSortedAutomations([]);
        setAutomationStepCheckResults([]);
        setStepsLoading(false);
        return;
      }

      if (Array.isArray(automations) && automations.length > 0) {
        // populate the automation steps with data on the associated resources
        let allAutomationSteps = [];
        let downloadedReportTemplates = [];
        try {
          const { allAutomationSteps: steps, downloadedReportTemplates: reportTemplates } =
            await getAutomationStepsData({
              steps: automations,
              cache: {
                reportTemplates: downloadedTemplates,
              },
              retrieveFromStorage: async (key) => Storage.get(key),
            });
          allAutomationSteps = steps;
          downloadedReportTemplates = reportTemplates;
        } catch (e) {
          ErrorLogger("Unable to retrieve automation steps data", e);
        }

        // update the cache so that large resources are not being requested multiple times
        if (typeof downloadedReportTemplates === "object") {
          setDownloadedTemplates((downloadedTemplates) => {
            return { ...downloadedTemplates, ...downloadedReportTemplates };
          });
        }

        // check automation steps to make sure they form a valid graph
        setAutomationStepCheckResults(
          checkAutomationStepsAreValid({
            automation: {
              triggers: [],
              conditions: [],
              ...item,
              automations: allAutomationSteps,
            },
            ancestorSteps,
          }),
        );

        // get the execution order, filtering out relevant smart values, creating adjacency list, and running a topological sort
        try {
          const sortedAutomationSteps = sortAutomationStepsByExecutionOrder({
            automation: { automations: allAutomationSteps },
          });
          // go through sorted automations and add additional info specific to each automation step type
          if (Array.isArray(sortedAutomationSteps) && sortedAutomationSteps.length > 0) {
            setSortedAutomations(
              sortedAutomationSteps.map((automationStep) => {
                return {
                  ...automationStep,
                  ...AUTOMATION_TYPES_CONFIG[automationStep.type],
                };
              }),
            );
          } else {
            setSortedAutomations([]);
          }
        } catch (e) {
          ErrorLogger("Cannot rearrange steps", e);
          setSortedAutomations(
            allAutomationSteps.map((automationStep) => {
              return {
                ...automationStep,
                ...AUTOMATION_TYPES_CONFIG[automationStep.type],
              };
            }),
          );
        }
      }
      setStepsLoading(false);
    };
    getData();
  }, [JSON.stringify(automations)]);

  const handleStepUpdate = async (newAutomations) => {
    const id = item?.id;
    if (performUpdates && id) {
      const { updateMutation } = generateGraphql("Automation");

      const inputAutomations = newAutomations
        .filter((step) => step?.id)
        .map((step) => {
          return formatAutomationStepForMutation({ step });
        });

      const input = {
        id,
        automations: inputAutomations,
      };

      await ItemMutation(updateMutation, input);
    }

    if (typeof onChangeCallback === "function") {
      onChangeCallback(newAutomations);
    }

    setAutomations(newAutomations);
  };

  const errorOrWarningAlerts = automationStepCheckResults.filter((result) => {
    return result.status === "error" || result.status === "warning";
  });

  // fetches smart values from triggers and conditions
  const { smartValues, setSmartValues } = useAutomationSmartValues({
    item: automation,
  });

  const readOnly = disableEdits || item?.enabled === true;

  return (
    <SmartValueContext.Provider
      value={{
        smartValues,
        setSmartValues,
        showLoopSmartValues,
      }}
    >
      <Card>
        {/*Error or Warning alerts from checks*/}
        <div>
          {Array.isArray(errorOrWarningAlerts) &&
            errorOrWarningAlerts.length > 0 &&
            errorOrWarningAlerts.map((checkResult) => {
              return <Alert severity={checkResult?.status}>{checkResult?.message}</Alert>;
            })}
        </div>
        <CardBody>
          {stepsLoading ? (
            <span>
              Loading Steps... <Loader />
            </span>
          ) : (
            <List
              sx={{
                width: "100%",
                maxWidth: "100%",
                bgcolor: "background.paper",
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {Array.isArray(sortedAutomations) &&
                sortedAutomations.length > 0 &&
                sortedAutomations.map((automationStep, index) => (
                  <AutomationListItem
                    // automation step by itself
                    automationStep={automationStep}
                    // the parent 'Automation' item is passed as the 'automation' prop
                    automation={automation}
                    key={`item${index}`}
                    automations={automations}
                    ancestorSteps={[...automations, ...(ancestorSteps || [])]}
                    setAutomations={handleStepUpdate}
                    organizationID={organizationID}
                    trigger={input?.trigger}
                    isTemplate={isTemplate}
                    readOnly={readOnly}
                    showLoopSmartValues={showLoopSmartValues}
                  />
                ))}
              {!readOnly && (
                <SelectAutomationModal
                  onSelect={(selected) => {
                    handleStepUpdate([...automations, { ...selected }]);
                  }}
                  organizationID={organizationID}
                  automations={sortedAutomations}
                  disabledStepTypes={disabledStepTypes}
                  trigger={input?.trigger}
                  isTemplate={isTemplate}
                  ancestorSteps={ancestorSteps}
                  automation={automation || input}
                  input={input}
                  showLoopSmartValues={showLoopSmartValues}
                />
              )}
            </List>
          )}
        </CardBody>
      </Card>
    </SmartValueContext.Provider>
  );
};

export default ConfigureAutomation;
