import React from "react";

import { generateGraphql } from "@rivial-security/generategraphql";

import { useGetManyEffect } from "../../../../../hooks/functional/useGetManyEffect";
import { withOrganizationCheck } from "../../../../../utils/Context/withOrganizationCheck";
import { useSystemDataGrid } from "../../../Systems/hooks/useSystemDataGrid";

/**
 * @description Displays Systems that are linked to a particular control category
 * @param {string} organizationID - ownerGroup ID of the Organization, Template, or OperationTeam
 * @param {object} item - the control category object
 */
const ControlCategorySystems = ({ organizationID, item }) => {
  const { getQuery } = generateGraphql("RiskControlCategory", ["systems"], {
    systems: `(limit: 1000) {
      items {
        id
        system {
          id
          name
          ownerGroup
          description
          hosting
          lastReviewed
          tags (limit: 100) { items { __typename id tag { id name description fontColor backgroundColor } } },
          pointOfContact { id ownerGroup firstName lastName title email },
          adminPointOfContact { id ownerGroup firstName lastName title email }
          inherentRisk
          residualRisk
          riskRating
          greatestEnterpriseRisk
          greatestBusinessRisk
          greatestKeyRiskIndicator
        }
      }
    }`,
  });

  const queryConfig = {
    query: null,
  };

  const gridConfig = {
    createResourceComponent: null,
  };

  const systemGrid = useSystemDataGrid({
    organizationID,
    ...queryConfig,
    ...gridConfig,
  });

  useGetManyEffect({
    query: getQuery,
    item,
    field: "systems",
    connectionField: "system",
    callback: systemGrid.setData,
  });

  return <div style={{ height: "30em" }}>{systemGrid.gridDisplay}</div>;
};

export default withOrganizationCheck(ControlCategorySystems);
