"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || (function () {
    var ownKeys = function(o) {
        ownKeys = Object.getOwnPropertyNames || function (o) {
            var ar = [];
            for (var k in o) if (Object.prototype.hasOwnProperty.call(o, k)) ar[ar.length] = k;
            return ar;
        };
        return ownKeys(o);
    };
    return function (mod) {
        if (mod && mod.__esModule) return mod;
        var result = {};
        if (mod != null) for (var k = ownKeys(mod), i = 0; i < k.length; i++) if (k[i] !== "default") __createBinding(result, mod, k[i]);
        __setModuleDefault(result, mod);
        return result;
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSystemRiskResults = exports.getRiskScores_enterpriseRisks = exports.getRiskScores_enterpriseRisk = exports.getRiskScores_businessRisks = exports.getRiskScores_businessRisk = exports.getRiskScore = exports.getRiskRatings = exports.getRiskAmount_KRI = exports.getRating_KRI = exports.getLossCurveValue = exports.getLossCurveRating = exports.getGreatestRiskCurves = exports.findLossToleranceExceedanceAtResidualLoss = exports.findLossToleranceHeightAtPoint = exports.findLossToleranceExceedance = exports.CALCULATION_CONTEXT = exports.getSystemKeyRiskIndicatorStats = exports.getAvailability = exports.getMaximumLossToleranceValue = exports.getLossToleranceCurveXValues = exports.formatLossToleranceCurve = exports.createMonteCarloSystemInput = exports.monteCarloCalculation = exports.monteCarloChangeCalculation = exports.saveMonteCarloSystemInputObject = exports.isRiskOverrideFieldEnabled = exports.isRiskControlOverrideFieldEnabled = exports.isRiskControlNonStandard = exports.getTotalNumberOfRecords = exports.getStandardLossAmountWithOverride = exports.getStandardLossAmount = exports.getMaxAvailability = exports.getLowerLossAmountWithOverride = exports.getLowerLossAmount = exports.getImpactFromAssetLink = exports.getImpactCost = exports.getIValue = exports.getCValue = exports.getCostOfControl = exports.getControlImplementationRating = exports.getControlEffectiveness = exports.getAValue = exports.getAnnualRateOfOccurrence = exports.getRandomOccurrenceNum = exports.getRandomLossAmount = exports.generateReusableRandoms = exports.findIndexOfRiskOverride = exports.findIndexOfRiskControlOverride = exports.checkSystemForRiskOverride = exports.checkSystemForRiskControlOverride = void 0;
exports.realTimeRiskGQL = void 0;
// Constants
const CALCULATION_CONTEXT_1 = require("./constants/CALCULATION_CONTEXT");
Object.defineProperty(exports, "CALCULATION_CONTEXT", { enumerable: true, get: function () { return CALCULATION_CONTEXT_1.CALCULATION_CONTEXT; } });
const createMonteCarloSystemInput_1 = require("./createMonteCarloSystemInput/createMonteCarloSystemInput");
Object.defineProperty(exports, "createMonteCarloSystemInput", { enumerable: true, get: function () { return createMonteCarloSystemInput_1.createMonteCarloSystemInput; } });
const checkSystemForRiskControlOverride_1 = require("./createMonteCarloSystemInput/functions/checkSystemForRiskControlOverride");
Object.defineProperty(exports, "checkSystemForRiskControlOverride", { enumerable: true, get: function () { return checkSystemForRiskControlOverride_1.checkSystemForRiskControlOverride; } });
const checkSystemForRiskOverride_1 = require("./createMonteCarloSystemInput/functions/checkSystemForRiskOverride");
Object.defineProperty(exports, "checkSystemForRiskOverride", { enumerable: true, get: function () { return checkSystemForRiskOverride_1.checkSystemForRiskOverride; } });
const findIndexOfRiskControlOverride_1 = require("./createMonteCarloSystemInput/functions/findIndexOfRiskControlOverride");
Object.defineProperty(exports, "findIndexOfRiskControlOverride", { enumerable: true, get: function () { return findIndexOfRiskControlOverride_1.findIndexOfRiskControlOverride; } });
const findIndexOfRiskOverride_1 = require("./createMonteCarloSystemInput/functions/findIndexOfRiskOverride");
Object.defineProperty(exports, "findIndexOfRiskOverride", { enumerable: true, get: function () { return findIndexOfRiskOverride_1.findIndexOfRiskOverride; } });
// Loss Exceedance Functions
const formatLossToleranceCurve_1 = require("./createMonteCarloSystemInput/functions/formatLossToleranceCurve");
Object.defineProperty(exports, "formatLossToleranceCurve", { enumerable: true, get: function () { return formatLossToleranceCurve_1.formatLossToleranceCurve; } });
const generateReusableRandoms_1 = require("./createMonteCarloSystemInput/functions/generateReusableRandoms");
Object.defineProperty(exports, "generateReusableRandoms", { enumerable: true, get: function () { return generateReusableRandoms_1.generateReusableRandoms; } });
Object.defineProperty(exports, "getRandomLossAmount", { enumerable: true, get: function () { return generateReusableRandoms_1.getRandomLossAmount; } });
Object.defineProperty(exports, "getRandomOccurrenceNum", { enumerable: true, get: function () { return generateReusableRandoms_1.getRandomOccurrenceNum; } });
const getAnnualRateOfOccurrence_1 = require("./createMonteCarloSystemInput/functions/getAnnualRateOfOccurrence");
Object.defineProperty(exports, "getAnnualRateOfOccurrence", { enumerable: true, get: function () { return getAnnualRateOfOccurrence_1.getAnnualRateOfOccurrence; } });
const getAvailability_1 = require("./createMonteCarloSystemInput/functions/getAvailability");
Object.defineProperty(exports, "getAvailability", { enumerable: true, get: function () { return getAvailability_1.getAvailability; } });
const getAValue_1 = require("./createMonteCarloSystemInput/functions/getAValue");
Object.defineProperty(exports, "getAValue", { enumerable: true, get: function () { return getAValue_1.getAValue; } });
const getControlEffectiveness_1 = require("./createMonteCarloSystemInput/functions/getControlEffectiveness");
Object.defineProperty(exports, "getControlEffectiveness", { enumerable: true, get: function () { return getControlEffectiveness_1.getControlEffectiveness; } });
const getControlImplementationRating_1 = require("./createMonteCarloSystemInput/functions/getControlImplementationRating");
Object.defineProperty(exports, "getControlImplementationRating", { enumerable: true, get: function () { return getControlImplementationRating_1.getControlImplementationRating; } });
const getCostOfControl_1 = require("./createMonteCarloSystemInput/functions/getCostOfControl");
Object.defineProperty(exports, "getCostOfControl", { enumerable: true, get: function () { return getCostOfControl_1.getCostOfControl; } });
const getCValue_1 = require("./createMonteCarloSystemInput/functions/getCValue");
Object.defineProperty(exports, "getCValue", { enumerable: true, get: function () { return getCValue_1.getCValue; } });
const getImpactCost_1 = require("./createMonteCarloSystemInput/functions/getImpactCost");
Object.defineProperty(exports, "getImpactCost", { enumerable: true, get: function () { return getImpactCost_1.getImpactCost; } });
const getImpactFromAssetLink_1 = require("./createMonteCarloSystemInput/functions/getImpactFromAssetLink");
Object.defineProperty(exports, "getImpactFromAssetLink", { enumerable: true, get: function () { return getImpactFromAssetLink_1.getImpactFromAssetLink; } });
const getIValue_1 = require("./createMonteCarloSystemInput/functions/getIValue");
Object.defineProperty(exports, "getIValue", { enumerable: true, get: function () { return getIValue_1.getIValue; } });
const getLossToleranceCurveXValues_1 = require("./createMonteCarloSystemInput/functions/getLossToleranceCurveXValues");
Object.defineProperty(exports, "getLossToleranceCurveXValues", { enumerable: true, get: function () { return getLossToleranceCurveXValues_1.getLossToleranceCurveXValues; } });
const getLowerLossAmount_1 = require("./createMonteCarloSystemInput/functions/getLowerLossAmount");
Object.defineProperty(exports, "getLowerLossAmount", { enumerable: true, get: function () { return getLowerLossAmount_1.getLowerLossAmount; } });
const getLowerLossAmountWithOverride_1 = require("./createMonteCarloSystemInput/functions/getLowerLossAmountWithOverride");
Object.defineProperty(exports, "getLowerLossAmountWithOverride", { enumerable: true, get: function () { return getLowerLossAmountWithOverride_1.getLowerLossAmountWithOverride; } });
const getMaxAvailability_1 = require("./createMonteCarloSystemInput/functions/getMaxAvailability");
Object.defineProperty(exports, "getMaxAvailability", { enumerable: true, get: function () { return getMaxAvailability_1.getMaxAvailability; } });
const getMaximumLossToleranceValue_1 = require("./createMonteCarloSystemInput/functions/getMaximumLossToleranceValue");
Object.defineProperty(exports, "getMaximumLossToleranceValue", { enumerable: true, get: function () { return getMaximumLossToleranceValue_1.getMaximumLossToleranceValue; } });
const getStandardLossAmount_1 = require("./createMonteCarloSystemInput/functions/getStandardLossAmount");
Object.defineProperty(exports, "getStandardLossAmount", { enumerable: true, get: function () { return getStandardLossAmount_1.getStandardLossAmount; } });
const getStandardLossAmountWithOverride_1 = require("./createMonteCarloSystemInput/functions/getStandardLossAmountWithOverride");
Object.defineProperty(exports, "getStandardLossAmountWithOverride", { enumerable: true, get: function () { return getStandardLossAmountWithOverride_1.getStandardLossAmountWithOverride; } });
// Monte Carlo Calculation Functions
const getTotalNumberOfRecords_1 = require("./createMonteCarloSystemInput/functions/getTotalNumberOfRecords");
Object.defineProperty(exports, "getTotalNumberOfRecords", { enumerable: true, get: function () { return getTotalNumberOfRecords_1.getTotalNumberOfRecords; } });
const isRiskControlNonStandard_1 = require("./createMonteCarloSystemInput/functions/isRiskControlNonStandard");
Object.defineProperty(exports, "isRiskControlNonStandard", { enumerable: true, get: function () { return isRiskControlNonStandard_1.isRiskControlNonStandard; } });
const isRiskControlOverrideFieldEnabled_1 = require("./createMonteCarloSystemInput/functions/isRiskControlOverrideFieldEnabled");
Object.defineProperty(exports, "isRiskControlOverrideFieldEnabled", { enumerable: true, get: function () { return isRiskControlOverrideFieldEnabled_1.isRiskControlOverrideFieldEnabled; } });
const isRiskOverrideFieldEnabled_1 = require("./createMonteCarloSystemInput/functions/isRiskOverrideFieldEnabled");
Object.defineProperty(exports, "isRiskOverrideFieldEnabled", { enumerable: true, get: function () { return isRiskOverrideFieldEnabled_1.isRiskOverrideFieldEnabled; } });
const saveMonteCarloSystemInputObject_1 = require("./createMonteCarloSystemInput/functions/saveMonteCarloSystemInputObject");
Object.defineProperty(exports, "saveMonteCarloSystemInputObject", { enumerable: true, get: function () { return saveMonteCarloSystemInputObject_1.saveMonteCarloSystemInputObject; } });
const findLossToleranceExceedance_1 = require("./lossToleranceExceedanceCalculation/findLossToleranceExceedance");
Object.defineProperty(exports, "findLossToleranceExceedance", { enumerable: true, get: function () { return findLossToleranceExceedance_1.findLossToleranceExceedance; } });
const findLossToleranceExceedanceAtResidualLoss_1 = require("./lossToleranceExceedanceCalculation/findLossToleranceExceedanceAtResidualLoss");
Object.defineProperty(exports, "findLossToleranceExceedanceAtResidualLoss", { enumerable: true, get: function () { return findLossToleranceExceedanceAtResidualLoss_1.findLossToleranceExceedanceAtResidualLoss; } });
const findLossToleranceHeightAtPoint_1 = require("./lossToleranceExceedanceCalculation/findLossToleranceHeightAtPoint");
Object.defineProperty(exports, "findLossToleranceHeightAtPoint", { enumerable: true, get: function () { return findLossToleranceHeightAtPoint_1.findLossToleranceHeightAtPoint; } });
const getSystemKeyRiskIndicatorStats_1 = require("./monteCarloCalculation/functions/getSystemKeyRiskIndicatorStats");
Object.defineProperty(exports, "getSystemKeyRiskIndicatorStats", { enumerable: true, get: function () { return getSystemKeyRiskIndicatorStats_1.getSystemKeyRiskIndicatorStats; } });
const monteCarloCalculation_1 = require("./monteCarloCalculation/monteCarloCalculation");
Object.defineProperty(exports, "monteCarloCalculation", { enumerable: true, get: function () { return monteCarloCalculation_1.monteCarloCalculation; } });
const monteCarloChangeCalculation_1 = require("./monteCarloChangeCalculation/monteCarloChangeCalculation");
Object.defineProperty(exports, "monteCarloChangeCalculation", { enumerable: true, get: function () { return monteCarloChangeCalculation_1.monteCarloChangeCalculation; } });
// riskRatings
var getGreatestRiskCurves_1 = require("./riskRatings/functions/getGreatestRiskCurves");
Object.defineProperty(exports, "getGreatestRiskCurves", { enumerable: true, get: function () { return getGreatestRiskCurves_1.getGreatestRiskCurves; } });
var getLossCurveRating_1 = require("./riskRatings/functions/getLossCurveRating");
Object.defineProperty(exports, "getLossCurveRating", { enumerable: true, get: function () { return getLossCurveRating_1.getLossCurveRating; } });
var getLossCurveValue_1 = require("./riskRatings/functions/getLossCurveValue");
Object.defineProperty(exports, "getLossCurveValue", { enumerable: true, get: function () { return getLossCurveValue_1.getLossCurveValue; } });
var getRating_KRI_1 = require("./riskRatings/functions/getRating_KRI");
Object.defineProperty(exports, "getRating_KRI", { enumerable: true, get: function () { return getRating_KRI_1.getRating_KRI; } });
var getRiskAmount_KRI_1 = require("./riskRatings/functions/getRiskAmount_KRI");
Object.defineProperty(exports, "getRiskAmount_KRI", { enumerable: true, get: function () { return getRiskAmount_KRI_1.getRiskAmount_KRI; } });
var getRiskRatings_1 = require("./riskRatings/functions/getRiskRatings");
Object.defineProperty(exports, "getRiskRatings", { enumerable: true, get: function () { return getRiskRatings_1.getRiskRatings; } });
var getRiskScore_1 = require("./riskRatings/functions/getRiskScore");
Object.defineProperty(exports, "getRiskScore", { enumerable: true, get: function () { return getRiskScore_1.getRiskScore; } });
var getRiskScores_businessRisk_1 = require("./riskRatings/functions/getRiskScores_businessRisk");
Object.defineProperty(exports, "getRiskScores_businessRisk", { enumerable: true, get: function () { return getRiskScores_businessRisk_1.getRiskScores_businessRisk; } });
var getRiskScores_businessRisks_1 = require("./riskRatings/functions/getRiskScores_businessRisks");
Object.defineProperty(exports, "getRiskScores_businessRisks", { enumerable: true, get: function () { return getRiskScores_businessRisks_1.getRiskScores_businessRisks; } });
var getRiskScores_enterpriseRisk_1 = require("./riskRatings/functions/getRiskScores_enterpriseRisk");
Object.defineProperty(exports, "getRiskScores_enterpriseRisk", { enumerable: true, get: function () { return getRiskScores_enterpriseRisk_1.getRiskScores_enterpriseRisk; } });
var getRiskScores_enterpriseRisks_1 = require("./riskRatings/functions/getRiskScores_enterpriseRisks");
Object.defineProperty(exports, "getRiskScores_enterpriseRisks", { enumerable: true, get: function () { return getRiskScores_enterpriseRisks_1.getRiskScores_enterpriseRisks; } });
var getSystemRiskResults_1 = require("./riskRatings/functions/getSystemRiskResults");
Object.defineProperty(exports, "getSystemRiskResults", { enumerable: true, get: function () { return getSystemRiskResults_1.getSystemRiskResults; } });
exports.realTimeRiskGQL = __importStar(require("./riskRatings/graphql/_realTimeRiskGQL"));
