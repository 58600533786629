import { CUSTOM_CONTROL_FRAMEWORK_DASHBOARD } from "@compliance/api";

import client from "../../../apollo/client";

export interface GetComplianceFrameworkOverviewPageTitleParams {
  resourceID?: string;
}

export const getComplianceFrameworkDashboardTitle = async ({
  resourceID,
}: GetComplianceFrameworkOverviewPageTitleParams): Promise<string | null> => {
  if (!resourceID) {
    return null;
  }

  const dashboardResult = await client.query({
    query: CUSTOM_CONTROL_FRAMEWORK_DASHBOARD,
    variables: { dashboardId: resourceID },
  });

  return dashboardResult?.data?.customControlFrameworkDashboard?.name ?? null;
};
