import _ from "lodash";

import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "@utils/Functions/deepDelete";
import { resources } from "role-utils";

/**
 * Deletes an Observation resource in the DB with all linked resources.
 * @param {string[]} itemsIDs - array of items IDs to delete
 * @param {object} config - configuration object
 * @param {boolean} config.deleteRecommendations - if true, will delete all Recommendations associated with this Observation
 * @param {boolean} config.deleteActionItems - if true, will delete all Action Items associated with the Recommendations associated with this Observation
 * @param {function} onJobStarted - callback function to get job
 * @returns {Promise<*>}
 */
export const deleteObservation = async (
  itemsIDs,
  { deleteRecommendations = false, deleteActionItems = false },
  onJobStarted,
) => {
  if (!Array.isArray(itemsIDs) && _.isPlainObject(itemsIDs)) {
    itemsIDs = [itemsIDs.id];
  } else if (!Array.isArray(itemsIDs)) {
    ErrorLogger("[deleteObservation] Missing Observation IDs array");
    return;
  }

  return await deepDelete({
    typename: resources.OBSERVATION,
    itemsIDs,
    config: {
      deleteRecommendations,
      deleteActionItems,
    },
    onJobStarted,
  });
};
