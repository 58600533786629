import React from "react";

import { modules } from "@rivial-security/role-utils";

import { getComplianceFrameworkDashboardTitle } from "@application/Navigation/functions/getComplianceFrameworkDashboardTitle";
import { NavigationModule, NavigationSectionName } from "@application/Navigation/types";
import { IconName } from "@components/Ions/icons/components/Icons";

import i18next from "../../../../i18n/i18n";

const OverviewPage = React.lazy(() => import("@compliance/OverviewPage"));
const DashboardOverviewPage = React.lazy(() => import("@compliance/DashboardOverviewPage"));
const EvidencePage = React.lazy(() => import("@evidence/EvidencePage"));
const ControlsPage = React.lazy(() => import("@controls/ControlsPage"));

export const complianceNavigation: NavigationModule = {
  moduleName: modules.COMPLIANCE,
  sectionName: NavigationSectionName.SERVICES,
  name: i18next.t("compliance:compliance"),
  path: "/compliance",
  deprecatedPaths: ["/continuous_compliance"],
  defaultRoutePath: "/overview",
  icon: IconName.COMPLIANCE,
  routes: [
    {
      path: "/overview",
      name: i18next.t("compliance:overview"),
      component: OverviewPage,
      navigationBar: {
        name: i18next.t("compliance:overview"),
      },
    },
    {
      path: "/overview/:id",
      name: i18next.t("framework dashboard title fallback", { ns: "compliance" }),
      component: DashboardOverviewPage,
      getTitle: getComplianceFrameworkDashboardTitle,
    },
    {
      path: "/evidence",
      name: i18next.t("compliance:evidence"),
      component: EvidencePage,
    },
    {
      path: "/controls",
      name: i18next.t("compliance:controls"),
      component: ControlsPage,
    },
  ],
};
