"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.customQueryFunctions = void 0;
const combineNotes_1 = require("../functions/combineNotes");
const daysUntilDate_1 = require("../functions/daysUntilDate");
const getFullName_1 = require("../functions/getFullName");
const getNestedField_1 = require("../functions/getNestedField/getNestedField");
const mathExpression_1 = require("../functions/mathExpression");
/**
 * Functions which are dynamically selected to be available to various fields of a custom query
 */
exports.customQueryFunctions = {
    //Point of Contact
    getFullName: getFullName_1.getFullName,
    //Object
    getNestedField: getNestedField_1.getNestedField,
    combineNotes: combineNotes_1.combineNotes,
    daysUntilDate: daysUntilDate_1.daysUntilDate,
    mathExpression: mathExpression_1.mathExpression,
};
