import { Alert } from "@mui/material";
import React, { useState } from "react";
import { Col } from "reactstrap";

import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import DashboardCard from "../../../../../../utils/GenericComponents/DashboardCard/components/DashboardCard";
import SelectDocuments from "../../../../Documents/components/SelectDocuments";
import UploadDocument from "../../../../Documents/components/UploadDocumentV2";

/**
 * Form for uploading a Document for an Evidence or selecting files for them (in case of a guest user)
 * @param {object} evidence - the evidence for which to get new documents
 * @param {string} organizationID - the organization that the evidence is a part of
 * @param {function} onSubmit - custom submit callback for each type of document selection
 * NON-GUEST: gets object {type: "documentUpload", documents: [array of uploaded document info from the database]}
 * GUEST: gets object {type: "documentUpload", files: [array of files to upload], isEncryptFile: boolean}
 * @param {boolean} isGuest - true if the user submitting evidence artifact is a guest user
 * @returns {JSX.Element} - UI for selecting and if not guest uploading documents
 */
const DocumentEvidenceForm = ({ evidence, organizationID, onSubmit, isGuest }) => {
  /**
   * Runs when the 'Upload' button is pressed in the file uploader,
   * passes the 'docs' to the evidenceActivity input
   * @param docs
   */
  const onDocumentSubmit = (docs) => {
    const activityInput = {
      type: EVIDENCE_ACTIVITY_TYPES.DOCUMENT_UPLOAD,
      documents: docs,
    };
    onSubmit?.(activityInput);
  };

  /**
   * Runs when the 'Continue' is pressed in the file selector passes
   * the selected files and encryption flag to evidenceActivity input
   * @param {object[]} files - all selected files from the user's computer
   * @param {boolean} - if true the user wants the files to be encrypted where stored
   */
  const onDocumentSelect = (input) => {
    const activityInput = {
      type: EVIDENCE_ACTIVITY_TYPES.DOCUMENT_UPLOAD,
      ...input,
    };
    onSubmit?.(activityInput);
  };

  /**
   * Checks if the user has selected multiple documents
   * This more of temporary solution until we can figure out how to handle multiple documents
   */
  const [isMultiDocuments, setIsMultiDocuments] = useState(false);
  const checkNumberOfDocuments = (event) => {
    if (Array.isArray(event?.filesData) && event?.filesData.length > 1) {
      setIsMultiDocuments(true);
    } else {
      setIsMultiDocuments(false);
    }
  };

  return (
    <DashboardCard title="Document Upload" style={{ height: "100%" }}>
      <Col>
        {isMultiDocuments && (
          <Alert severity={"warning"} style={{ marginBottom: "1em" }}>
            Please note when uploading multiple documents, a Searchable PDF Artifact will not be generated.
          </Alert>
        )}
        {evidence ? (
          isGuest ? (
            <SelectDocuments onSubmit={onDocumentSelect} onSelected={checkNumberOfDocuments} />
          ) : (
            <UploadDocument
              key={`${evidence.ownerGroup}uploadDocument`}
              organizationID={organizationID}
              evidence={evidence}
              collapse={true}
              onSubmit={onDocumentSubmit}
              onSelected={checkNumberOfDocuments}
            />
          )
        ) : (
          "There was an issue fetching the associated Evidence"
        )}
      </Col>
    </DashboardCard>
  );
};

export default DocumentEvidenceForm;
