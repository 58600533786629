import { resources } from "@rivial-security/role-utils";

import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "@utils/Functions/deepDelete";

/**
 * Deletes Audit resource in the DB with all linked resources.
 */
export const deleteAudit = async (itemsIDs: string[]): Promise<unknown> => {
  if (!Array.isArray(itemsIDs)) {
    ErrorLogger("[deleteAudit] Missing Audit IDs array");
    return;
  }

  return await deepDelete({
    // @ts-expect-error: type mismatch with JSDoc
    typename: resources.AUDIT,
    itemsIDs,
  });
};
