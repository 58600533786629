"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateListQueryGraphql = exports.generateListByQueryGraphql = exports.generateGraphqlFields = exports.generateGraphql = void 0;
var generateGraphql_1 = require("./generateGraphql");
Object.defineProperty(exports, "generateGraphql", { enumerable: true, get: function () { return generateGraphql_1.generateGraphql; } });
var generateGraphql_2 = require("./generateGraphql");
Object.defineProperty(exports, "generateGraphqlFields", { enumerable: true, get: function () { return generateGraphql_2.generateGraphqlFields; } });
var generateListByQueryGraphql_1 = require("./generateListByQueryGraphql");
Object.defineProperty(exports, "generateListByQueryGraphql", { enumerable: true, get: function () { return generateListByQueryGraphql_1.generateListByQueryGraphql; } });
var generateListQueryGraphql_1 = require("./generateListQueryGraphql");
Object.defineProperty(exports, "generateListQueryGraphql", { enumerable: true, get: function () { return generateListQueryGraphql_1.generateListQueryGraphql; } });
