import _ from "lodash";

import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "@utils/Functions/deepDelete";
import { resources } from "role-utils";

/**
 * Deletes a Recommendation resource in the DB with all linked resources.
 * @param {string[]} itemsIDs - array of items IDs to delete
 * @param {object} config - configuration object
 * @param {boolean} config.deleteActionItems - if true, will delete all Action Items associated with this Recommendation
 * @param {boolean} config.deleteObservations - if true, will delete all Observations associated with this Recommendation
 * @param {function} onJobStarted - callback function to get job
 */
export const deleteRecommendation = async (
  itemsIDs,
  { deleteActionItems = false, deleteObservations = false },
  onJobStarted,
) => {
  if (!Array.isArray(itemsIDs) && _.isPlainObject(itemsIDs)) {
    itemsIDs = [itemsIDs.id];
  } else if (!Array.isArray(itemsIDs)) {
    ErrorLogger("[deleteRecommendation] Missing Recommendation IDs array");
    return;
  }

  return await deepDelete({
    typename: resources.RECOMMENDATION,
    itemsIDs,
    config: {
      deleteActionItems,
      deleteObservations,
    },
    onJobStarted,
  });
};
