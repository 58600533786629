import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { isLocalhost } from "@utils/Functions/isLocalhost";

import { AmplifyTokenManager } from "../auth/AmplifyTokenManager";
import { GRAPHQL_ENDPOINTS_ENV_URL, GRAPHQL_ENDPOINTS_ENV_URL_LOCALHOST } from "../env-config";

const httpLink = new HttpLink({
  uri: isLocalhost() ? GRAPHQL_ENDPOINTS_ENV_URL_LOCALHOST : GRAPHQL_ENDPOINTS_ENV_URL,
});

const client = new ApolloClient({
  link: ApolloLink.from([
    setContext(async () => ({
      headers: {
        Authorization: `Bearer ${await new AmplifyTokenManager().getValidToken()}`,
      },
    })),
    httpLink,
  ]),
  cache: new InMemoryCache(),
});

export default client;
