import LinearProgress from "@mui/material/LinearProgress";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import React, { ReactElement, useState } from "react";

import { isNullOrUndefined } from "@rivial-security/func-utils";

import { handleIcon } from "../../../utils/Functions/Icon/getIcon";
import Dashboard from "../../../utils/GenericComponents/Dashboard";
import DataLoader from "../../../utils/LoadingComponents/DataLoader";
import { LoadingSpinner } from "../../../utils/LoadingComponents/LoadingSpinner";
import PermissionsOverlay from "../../../utils/Overlays/PermissionsOverlay";
import { useGUID } from "../../functional/useGUID";
import { useStateEffect } from "../../functional/useStateEffect";
import { useDuplicateItems } from "../useDuplicateItems";

import { CustomNoRowsOverlay } from "./components/CustomNoRowsOverlay";
import DataGridFooter from "./components/DataGridFooter";
import { useDataGridColumns } from "./hooks/useDataGridColumns";
import { useDataGridColumnVisibility } from "./hooks/useDataGridColumnVisibility/useDataGridColumnVisibility";
import { useDataGridData } from "./hooks/useDataGridData";
import { useDataGridDeleteItems } from "./hooks/useDataGridDeleteItems";
import { useDataGridDetails } from "./hooks/useDataGridDetails";
import { useDataGridEditItems } from "./hooks/useDataGridEditItems/useDataGridEditItems";
import { useDataGridFiltering } from "./hooks/useDataGridFiltering/useDataGridFiltering";
import { useDataGridForm } from "./hooks/useDataGridForm";
import { useDataGridPinnedColumns } from "./hooks/useDataGridPinning/useDataGridPinning";
import { useDataGridSearch } from "./hooks/useDataGridSearch";
import { useDataGridSelectedItems } from "./hooks/useDataGridSelectedItems";
import { useDataGridSorting } from "./hooks/useDataGridSorting/useDataGridSorting";
import { ToolbarComponent, useDataGridToolbar } from "./hooks/useDataGridToolbar";
import { useDataGridViews } from "./hooks/useDataGridViews/useDataGridViews";

/**
 * Input Object Into the Data Grid Hook
 * @typedef {object} UseDataGridProps
 * **** Required Fields ****
 * @param {DataGridField[]}    fields        - field configuration objects
 * @param {string}      module                  - module enumerable used for role checking
 * @param {string}      resource                - resource enumerable used for role checking
 * @param {string}      organizationID          - module enumerable used for role checking
 *
 * **** Commonly Used Fields ****
 * @param {boolean}     [cardDisplay = false]       - boolean that will render the 'display' property as a Card
 * @param {boolean}     [dashboardDisplay = false]  - boolean that will render the 'display' property as a Dashboard
 * @param {ReactElement}  [createResourceComponent]   - React element to use for the Form component for the grid. Forwarded props:
 * @param {ReactElement}  [detailsComponent]      - React element to use for the Details Component for each row. Forwarded props:
 * @param {ReactElement[]} [headerButtons]        - Optional array of React elements to render in the header of the Card (if enabled)
 * @param {ReactElement|string} [icon]            - Optional icon to render in the top left corner of the Card (if enabled)
 * @param {object}      [filters]               - filters to pass in to the AppSync graphql query
 * @param {object}      [query]                 - graphql query string to use in the appsync call
 * @param {string}      [route]                 - route string used for the details page buttons
 * @param {string}      [helpCenterRoute]            - route used to open specific help center articles
 * @param {string}      [title]                 - Title for the Card (if enabled)
 * @param {string}      [subTitle]              - subTitle for the Card (if enabled)
 * @param {object}      [sx]                    - MUI sx prop passed to the DataGridPro library component
 * @param {string}      [typename]              - typename corresponding to the database model, used to pre-fill the grid header
 * @param {string}      [persistenceUUID]       - UUID used to persist the grid settings, the id of grid view in the user preferences, also used in element ids
 * @param {object}      [wrapperStyle]          - style object spread into the <div> that wraps the DataGridPro component
 *
 * **** Optional Fields ****
 * - grid DATA settings
 * @param {object[]}    [data = []]               - optional field for pre-filling the grid with data
 * @param {object[]}    [externalData]            - optional field, takes priority over 'data', does not get modified by grid operations directly
 * @param {function}    [setExternalCustomFieldData] - setter for internal data for collected for custom fields to be added into externalData
 * @param {object}      [externalCustomFieldData] - custom field data to init the data grid with
 * @param {function}    [queryExternalApi]        - function that is used to populate the data from an external source
 * @param {function}    [formatNewItem]           - before getNewItem is called and a new item from the form is added to the grid this optional function can be used to format the new item
 * @param {function}    [getNewItem]              - callback fired when a new item is created from the grid
 * @param {function}    [normalizeData]           - function that passed the entire query array to manipulate the data before it makes it to the grid
 * @param {function}    [normalizeRowData]        - function that passed each item of the query array individually to manipulate the data before it makes it to the grid
 * @param {function}    [finalizeData]            - function to finalize data after the query
 * @param {function}    [queryCallback]           - function that called whenever the query returns data
 * @param {function}    [refreshCallback]         - use to notify of refresh button hit and also to conditionally disable internal data refresh
 * @param {object}      [variables]               - custom variables passed to the appsync query
 * @param {function}    [resetFunction]           - function that called when user presses the reset button for the grid
 * @param {boolean}     [isLoading]               - overrides and sets the loading state of the grid
 * @param {number}      [limit]                   - sets the limit of the query
 * @param {EnhancedCustomField[]} [customFields]  - custom fields that are rendered dynamically after initial load
 * @param {boolean}     [customFieldsReadOnly]    - determines if custom fields should be forced read-only
 *
 * - grid OPERATION settings
 * @param {string[]}    [options]              - array of operations to enable, chooses are: 'details', 'delete', 'duplicate' and 'edit'
 * @param {boolean}     [enableQuickDetails]   - determines if the 'view' button is visible. Automatically on when 'detailsComponent' is passed in, but can be overridden
 * @param {boolean}     [enableExport]         - determines if the grid will allow the user to export data to CSV
 * @param {object[]}    [customActions]        - array of additional actions to for the action button
 * @param {ReactElement[]} [customExports]      - array of custom export button components to add to the 'Export' menu in the toolbar.
 *   @example
 *   {
 *     text: "Set Compliance",
 *     icon: <FactCheckIcon />,
 *     onClick: (item) => {...},
 *   }
 * @param {object[]}    [customOptions]        - array of additional actions for the toolbar
 *   @example
 *   {
 *     component: <Button/>
 *   }
 *
 * - CREATE operations
 * @param {JSXElement}  [createItemModalHeader] - optional field for overriding the Header component of the Create Resource Modal
 * @param {string}      [createResourceComponentWidth] - optional css string for the width of the Create Resource modal
 * @param {string}      [createResourceButtonText] - optional string to override the text of the Create Resource button, such as "Link"
 *
 * - DUPLICATION operations
 * @param {object}      [duplicationSettings]   - duplication settings configuration object
 * @param {string}      [duplicateButtonText]   - optional string to override the text of the duplicate button
 * @param {string}      [duplicateButtonTitle]  - the text of the duplicate button tooltip
 *
 * - DELETE operations
 * @param {string}      [deleteButtonText]           - optional string to override the text of the delete button, such us "Unlink"
 * @param {string}      [deleteButtonTitle]          - optional delete button tooltip text
 * @param {function}    [deleteCallback]             - optional function to call once item is already deleted (takes in {items: [deletedItem, deletedItem]})
 * @param {function}    [deleteFunction]             - the function used to remove an item from the database (and grid)
 * @param {string}      [deleteFunctionNote]         - optional field for displaying a note in the delete confirmation dialog
 * @param {boolean}     [deleteFunctionWithoutQueue] - if TRUE will not display the toast informing users that the item will be deleted as part of a queue
 * @param {object}      [deleteOptions]              - optional object that matches useForm fieldConfig for the delete confirmation dialog
 * @param {string}      [primaryField = "name"]      - optional field for displaying a field other than 'name' in confirmation dialogs
 *
 * - SELECT operations
 * @param {function}    [onSelectCallback]      - function that called when user presses the single 'select' button for a row (if enableSelectButton === true)
 * @param {boolean}     [enableSelectButton]    - determines if the single 'select' button is displayed for each row
 * @param {boolean}     [enableSelection]       - determines if the grid will allow the user to go into 'selection' mode (multiple selection with checkboxes).
 * @param {function}    [onSelectedDataChange]  - callback when the selected data state changes (multiple selection with checkboxes)
 *
 * - grid DETAILS settings
 * @param {object}      [config]                - config object for the built in details hook
 * @param {function}    [getRouteDisabled]      - determines if a route nav link should be disabled, returns a string to display a tooltip explanation and null if route is enabled
 * @param {string}      [detailsTitle]          - custom details modal header title
 * @param {object}      [detailsHook]           - a custom details hook to display instead of the built-in details component
 * @param {string|function} [detailsLink]        - NOTE: detailsComponent and detailsHook take priority, nevertheless if string provided will be used as prefix before the id, if function will be called with the row data and should return a string
 * @param {string}      [detailsButtonText]     - optional text override for the details action in the action button menu
 * @param {string}      [detailsButtonTitle]    - optional text override for the open details button tooltip
 *
 *
 * - additional visual settings
 * @param {boolean}     [disableVirtualization=false] - optionally disable virtualization of rows, useful to limit scroll jitter without a performance drop when there is a guaranteed small amount of dynamic height rows
 * @param {boolean}     [disableViews]          - optional flag for disabling data grid views components in the footer (effectively disables persistence)
 * @param {boolean}     [showHeader = true]     - boolean that toggles the header of the Card (if enabled)
 * @param {boolean}     [hideFooter = false]    - boolean that hides the footer of the grid (if enabled)
 * @param {boolean}     [disableRoleChecking]   - optional flag for disabling Role checking
 * @param {string[]}    [visibleFields]         - optional array of field names that are visible in the grid
 * @param {function}    [getEstimatedRowHeight] - forwards directly to MUI Data Grid. Fired for each row to estimate the height used to calculate the scrollbar
 * @param {function}    [onColumnHeaderClick]   - forwards directly to MUI Data Grid. Fired when a column header is clicked
 * @param {function}    [onTargetViewChange]    - callback for when the grids visual settings change
 * @param {function}    [onSelectedViewChange]  - callback when user has persistence view loaded for the first time or a new view selected from the drop down
 **/

/**
 * Fully Featured DataGrid using the material-ui DataGridPro component
 * @param {UseDataGridProps} props
 */
export const useDataGrid = ({
  // **** Required Fields **** //
  fields,
  module,
  resource,
  organizationID,

  // **** Commonly Used Fields **** //
  cardDisplay = false,
  dashboardDisplay = false,
  createResourceComponent,
  detailsComponent,
  headerButtons,
  icon,
  filters,
  query,
  route,
  helpCenterRoute,
  title,
  subTitle,
  sx = {},
  typename,
  persistenceUUID,
  wrapperStyle = {},

  // **** Optional Fields **** //
  // - grid DATA settings
  data: initialData,
  externalData,
  setExternalCustomFieldData,
  externalCustomFieldData,
  queryExternalApi,
  formatNewItem,
  getNewItem,
  normalizeData,
  normalizeRowData,
  finalizeData,
  queryCallback,
  refreshCallback,
  updateCallback,
  variables,
  resetFunction: externalResetFunction,
  isLoading: isLoadingInput,
  limit,
  customFields = [],
  customFieldsReadOnly = true,

  // - grid OPERATION settings
  options,
  enableQuickDetails = !isNullOrUndefined(detailsComponent), // Note, this is default enabled when there's a detailsComponent. Can be manually overridden.
  enableExport = true,
  customActions, // action button additional options (view details button)
  customExports, // export button additional options
  customOptions, // toolbar additional options

  // - CREATE operations
  createItemModalHeader,
  createResourceComponentWidth = "60vw",
  createResourceButtonText,

  // - DUPLICATION operations
  duplicationSettings,
  duplicateButtonText,
  duplicateButtonTitle,

  // - UPDATE operations
  updateMutation,
  editFunction,
  editButtonText,
  editFunctionNote,
  onEditCallback,

  // - DELETE operations
  deleteButtonText,
  deleteButtonTitle,
  deleteCallback,
  deleteFunction,
  deleteFunctionNote,
  deleteFunctionWithoutQueue,
  deleteOptions,
  primaryField = "name",

  // - SELECT operations
  onSelectCallback,
  enableSelectButton = false,
  enableSelection = false,
  onSelectedDataChange,

  // - grid DETAILS settings
  config, //used by the details modal
  getRouteDisabled,
  detailsTitle,
  detailsHook,
  detailsLink,
  detailsButtonText,
  detailsButtonTitle,

  // - additional visual settings
  disableVirtualization = false,
  disableViews = false,
  showHeader = true,
  hideFooter = false,
  disableRoleChecking = false,
  visibleFields,
  getEstimatedRowHeight = () => 200,
  onColumnHeaderClick,
  onTargetViewChange,
  onSelectedViewChange,

  // **** Deprecated Fields **** //
  headerIcon, // @deprecated - use 'icon' instead
}) => {
  // The Material-UI grid internal reference for advanced use-cases
  const apiRef = useGridApiRef();

  // Handles Loading States
  const [isLoading, setIsLoading] = useStateEffect(false, [isLoadingInput], () => isLoadingInput);
  const [loadingFields, setLoadingFields] = useState([]);

  /**
   * Handles grid views functionality and persistence
   *  - exposes `targetView` which defines custom sorting, filtering, etc. state of the entire grid
   *  - exposes change functions that modify the `targetView` state based on user interaction with the grid
   */
  const { viewControlsDisplay, targetView, changeTargetView, changeTargetViewColumns } = useDataGridViews({
    gridID: persistenceUUID,
    apiRef,
    onTargetViewChange,
    onSelectedViewChange,
  });

  // Handle which columns are visible
  const { columnVisibilityModel } = useDataGridColumnVisibility({
    targetView,
    fields,
    customFields,
    enableSelectButton,
  });

  // Holds the current state of the DataGrid.
  // This state is single source of truth
  // Handles all query information and passes handlers down
  const {
    data,
    setData,
    updateItemById: updateItemByIdInternal,
    addItem,
    getVisibleRows,
    resetFunction,
  } = useDataGridData({
    initialData,
    externalData,
    setExternalCustomFieldData,
    externalCustomFieldData,
    apiRef,
    query,
    organizationID,
    queryExternalApi,
    setIsLoading,
    variables,
    filters,
    limit,
    normalizeData,
    normalizeRowData,
    finalizeData,
    queryCallback,
    resetFunction: externalResetFunction,
    refreshCallback,
    updateCallback,
    module,
    resource,
    customFields,
    customFieldsReadOnly,
    loadingFields,
    setLoadingFields,
    columnVisibilityModel,
  });

  const { modal: createResourceModal } = useDataGridForm({
    createItemModalHeader,
    createResourceComponent,
    formatNewItem,
    getNewItem,
    resetFunction,
    createResourceComponentWidth,
    typename,
    addItem,
    module,
    resource,
    disableRoleChecking,
  });

  // Handles the state for the currently selected items.
  //      See: https://mui.com/components/data-grid/filtering/#quick-filter
  // Also handles the hasNext, hasPrevious, and selected item adjustment for the details modals
  //      See: https://mui.com/components/data-grid/state/#direct-selector-access
  const {
    hasNext,
    hasPrevious,
    detailsItem,
    adjustSelectedItemIndex,
    setDetailsItem,
    selectedIDs,
    setSelectedIDs,
    checkboxSelection,
    setCheckboxSelection,
    selectedData,
    setSelectedData,
  } = useDataGridSelectedItems({
    apiRef,
    enableSelection,
    onSelectedDataChange,
  });

  /**
   * Handles updating internal state such as selectedData when the updateItemById function is called.
   * @param {object} params - the updated resource, with 'id' and the field that was updated
   */
  const updateItemById = (params) => {
    if (params?.id) {
      setSelectedData((current) => {
        const foundItem = current.find((row) => row.id === params.id);
        if (!isNullOrUndefined(foundItem)) {
          Object.assign(foundItem, { ...params });
        }

        return current;
      });
    }

    return updateItemByIdInternal(params);
  };

  // Handles deleting selected items
  const { modal: deleteItemsModal } = useDataGridDeleteItems({
    selectedData,
    deleteButtonText,
    deleteFunction,
    onDeleteCallback: deleteCallback,
    data,
    resetFunction,
    deleteFunctionNote,
    deleteFunctionWithoutQueue,
    primaryField,
    deleteOptions,
    typename,
  });

  // Handle selected item duplication if enabled
  const duplicateItemsHook = useDuplicateItems({
    items: selectedData,
    typename,
    duplicationSettings,
    organizationID,
    resetFunction,
  });

  const [guid] = useGUID();

  // Aggregates all of the relevant grid context state, functions, and handlers to pass to all grid children, and up through the hook
  const gridContext = {
    guid,

    fields,
    module,
    resource,
    disableRoleChecking,
    organizationID,

    detailsComponent,
    route,
    helpCenterRoute,
    typename,
    persistenceUUID,

    data,
    resetFunction,
    customFields,
    customFieldsReadOnly,

    options,
    enableQuickDetails,
    detailsLink,
    enableExport,
    customActions,
    customExports,
    customOptions,

    duplicateItemsHook,
    duplicateButtonText,
    duplicateButtonTitle,

    editFunction,
    editButtonText,
    editFunctionNote,
    onEditCallback,
    updateMutation,

    deleteButtonText,
    deleteButtonTitle,
    deleteFunction,
    deleteFunctionNote,
    deleteItemsModal,

    onSelectCallback,
    enableSelectButton,
    enableSelection,
    selectedIDs,
    setSelectedIDs,
    checkboxSelection,
    setCheckboxSelection,

    config,
    detailsTitle,
    detailsHook,
    detailsButtonText,
    detailsButtonTitle,
    detailsItem,
    setDetailsItem,

    apiRef,
    hasNext,
    hasPrevious,
    adjustSelectedItemIndex,
    getVisibleRows,
    updateItemById,
    visibleFields,
  };

  // Handles the grid details row details UI
  const detailsModal = useDataGridDetails({
    getRouteDisabled,
    gridContext,
    updateItemById,
  });

  // Handles the Data Grid column configuration
  const { columns } = useDataGridColumns({
    gridContext,
    fields,
    targetView,
    detailsModal,
    loadingFields,
  });

  // Handle which columns are pinned
  const { pinnedColumns } = useDataGridPinnedColumns({
    targetView,
    fields,
  });

  // Handle filtering model state based on grid view
  const { filterModel } = useDataGridFiltering({ targetView, fields });

  // Handle sorting model state based on grid view
  const { sortModel } = useDataGridSorting({
    targetView,
    fields,
  });

  // Handles bulk editing of items
  const { modal: editItemsModal } = useDataGridEditItems({
    primaryField,
    columns,
    selectedData,
    editFunction,
    editButtonText,
    options,
    resetFunction,
    editFunctionNote,
    onEditCallback,
    updateMutation,
    organizationID,
    fields,
  });

  // Handles the custom 'Quick Filter' functionality for the DataGrid.
  // See: https://mui.com/components/data-grid/filtering/#quick-filter
  const { searchText, requestSearch, searchResults } = useDataGridSearch({
    data,
    columns,
  });

  // Handles the toolbar
  const { toolbarProps } = useDataGridToolbar({
    searchText,
    requestSearch,
    createResourceModal,
    createResourceComponent,
    checkboxSelection,
    setCheckboxSelection,
    selectedIDs,
    setSelectedIDs,
    deleteItemsModal,
    editItemsModal,
    deleteFunction,
    options,
    module,
    helpCenterRoute,
    resource,
    disableRoleChecking,
    deleteButtonText,
    customExports,
    customOptions,
    resetFunction,
    enableSelection,
    enableExport,
    duplicateItemsHook,
    createResourceButtonText,
    columns,
    persistenceUUID,
    typename,
  });

  const display = (
    <div
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        minHeight: "30vh",
        flex: 1,
        ...wrapperStyle,
      }}
    >
      <PermissionsOverlay module={module} resource={resource} disableRoleChecking={disableRoleChecking}>
        <DataLoader isLoading={false} isEnoughData={true}>
          {detailsModal.modal}
          <DataGridPro
            // NOTE: this is a last line of defense to protect the grid from crashing if there is no row.id field
            getRowId={(row) => row?.id || JSON.stringify(row)}
            loading={isLoading}
            hideFooter={hideFooter}
            disableRowSelectionOnClick
            apiRef={apiRef}
            rows={searchText === "" ? data : searchResults}
            columns={columns}
            localeText={{
              filterOperatorNotContains: "not contains",
            }}
            onRowSelectionModelChange={setSelectedIDs}
            rowSelectionModel={selectedIDs}
            slots={{
              toolbar: ToolbarComponent,
              loadingOverlay: LinearProgress,
              noRowsOverlay: CustomNoRowsOverlay,
              footer: DataGridFooter,
            }}
            slotProps={{
              toolbar: toolbarProps,
              row: { id: `data-grid-row-${persistenceUUID}` },
              footer: {
                viewControlsDisplay,
                disableViews,
              },
            }}
            onCellKeyDown={(params, event) => event.stopPropagation()}
            checkboxSelection={checkboxSelection}
            disableVirtualization={disableVirtualization}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "8px",
              },
              ".MuiDataGrid-footerContainer": {
                minHeight: "2.0em",
              },
              // NOTE: needed for the grid to inherit parent height after v6 upgrade
              // https://github.com/mui/mui-x/issues/10520#issuecomment-1749507243
              [`& .MuiDataGrid-main`]: {
                flex: "1 1 0px",
                overflow: "auto",
              },
              ".MuiDataGrid-columnHeader": {
                backgroundColor: "white",
              },
              ".MuiDataGrid-filler": {
                backgroundColor: "white",
              },
              ".MuiDataGrid-scrollbarFiller": {
                backgroundColor: "white",
              },
              ...sx,
            }}
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={getEstimatedRowHeight}
            onColumnHeaderClick={onColumnHeaderClick}
            onColumnOrderChange={() => changeTargetViewColumns({})}
            onColumnWidthChange={(columnChange) => changeTargetViewColumns({ columnChange })}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={(pinnedColumns) => changeTargetView({ pinnedColumns })}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(columnVisibilityModel) => changeTargetView({ columnVisibilityModel })}
            sortModel={sortModel}
            onSortModelChange={(sortModel) => {
              changeTargetView({ sortModel });
            }}
            filterModel={filterModel}
            onFilterModelChange={(filterModel) => {
              changeTargetView({ filterModel });
            }}
          />
        </DataLoader>
      </PermissionsOverlay>
    </div>
  );

  const cardDisplayComponent = (
    <div className="e-card">
      {showHeader && (
        <div className="e-card-header">
          <div className="e-card-header-caption">
            <div className="e-card-title">
              <h5 className="dashboard-title">
                {isLoading ? (
                  <LoadingSpinner style={{ marginRight: "0.5em" }} />
                ) : (
                  headerIcon && <span style={{ marginRight: "0.75em" }}>{handleIcon(headerIcon)}</span>
                )}
                {title}
              </h5>
            </div>
          </div>
          {headerButtons && headerButtons.map((button, index) => React.cloneElement(button, { key: guid + index }))}
        </div>
      )}
      <div className="e-card-content" style={{ height: "75vh" }}>
        {display}
      </div>
    </div>
  );

  const dashboardDisplayComponent = (
    <Dashboard
      title={title}
      titleStyle={sx?.title}
      subTitle={subTitle}
      icon={headerIcon || icon}
      resetFunction={resetFunction}
      headerButtons={headerButtons}
      sx={{
        container: sx?.dashboardContainer,
        actionButton: sx?.dashboardActionButton,
      }}
    >
      <div
        style={{
          height: "100%",
        }}
      >
        {display}
      </div>
    </Dashboard>
  );

  return {
    cardDisplay: cardDisplayComponent,
    dashboardDisplay: dashboardDisplayComponent,
    display: (cardDisplay ? cardDisplayComponent : dashboardDisplay) ? dashboardDisplayComponent : display,
    gridDisplay: display, // For backwards compatibility with the useGrid hook
    data,
    setData,
    selectedIDs,
    setSelectedIDs,
    selectedData,
    setSelectedData,
    setCheckboxSelection,
    isLoading,
    setIsLoading,
    resetFunction,
    createResourceModal,
    apiRef,
    targetView,
    changeTargetView,
  };
};
