import React from "react";

import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";
import AttestedActivity from "../components/AttestedActivity";
import CreatedAtActivity from "../components/CreatedAtActivity";
import DocumentActivity from "../components/DocumentActivity";
import ExpiredActivity from "../components/ExpiredActivity";
import ExpiringSoonActivity from "../components/ExpiringSoonActivity";
import ExternalUrlActivity from "../components/ExternalUrlActivity";
import FrequencyChangedActivity from "../components/FrequencyChangedActivity";
import MeetingActivity from "../components/MeetingActivity";
import PendingValidationActivity from "../components/PendingValidationActivity";
import PhishingActivity from "../components/PhishingActivity";
import PointOfContactAssignedActivity from "../components/PointOfContactAssignedActivity";
import PointOfContactUnassignedActivity from "../components/PointOfContactUnassignedActivity";
import PolicyActivity from "../components/PolicyActivity";
import ReportActivity from "../components/ReportActivity";
import RiskChangeActivity from "../components/RiskChangeActivity";
import StatusChangedManuallyActivity from "../components/StatusChangedManuallyActivity";
import TrainingActivity from "../components/TrainingActivity";
import TypeChangedActivity from "../components/TypeChangedActivity";
import ValidatedActivity from "../components/ValidatedActivity";

/**
 * @description Displays a component based on the type of Evidence Activity
 * @param {EvidenceActivityType} type - the type of Evidence Activity
 * @param {EvidenceActivity} item - the Evidence Activity
 * @returns {JSX.Element}
 */
export const getTimelineContent = ({ type, ...item }) => {
  switch (type) {
    case EVIDENCE_ACTIVITY_TYPES.EXPIRING_SOON:
      return <ExpiringSoonActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.EXPIRED:
      return <ExpiredActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.VALIDATED:
      return <ValidatedActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.DOCUMENT_UPLOAD:
      return <DocumentActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.REPORT_UPLOAD:
      return <ReportActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.ATTESTED:
      return <AttestedActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.MEETING:
      return <MeetingActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.POLICY:
      return <PolicyActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.RISK_CHANGE:
      return <RiskChangeActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.PENDING_VALIDATION:
      return <PendingValidationActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.TRAINING:
      return <TrainingActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.PHISHING:
      return <PhishingActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.EXTERNAL_URL:
      return <ExternalUrlActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.POINT_OF_CONTACT_ASSIGNED:
      return <PointOfContactAssignedActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.POINT_OF_CONTACT_UNASSIGNED:
      return <PointOfContactUnassignedActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.FREQUENCY_CHANGED:
      return <FrequencyChangedActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.STATUS_CHANGED_MANUALLY:
      return <StatusChangedManuallyActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.TYPE_CHANGED:
      return <TypeChangedActivity {...item} />;
    case EVIDENCE_ACTIVITY_TYPES.EVIDENCE_CREATED:
      return <CreatedAtActivity {...item} />;
    default:
      return type;
  }
};
