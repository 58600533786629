"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertStringArrayIntoOrderedList = exports.keywordSort = exports.getFriendlyRating = exports.getBase64ImageDimensions = exports.formattedName = exports.extractHTMLContent = exports.encodeBytesToString = exports.decodeSpecialHtml = exports.createCommaString = exports.convertToKebabCase = exports.convertCamelCaseToSentence = exports.concatNotes = exports.capitalizeFirstLetter = exports.updateObjectInArray = exports.tryParse = exports.sortObjectsByField = exports.removeObjectKey = exports.removeObjectFromArray = exports.removeDuplicateObjects = exports.omitDeep = exports.isObject = exports.hasProperty = exports.getPropertiesFromObject = exports.getAllLinkedItems = exports.flattenArrayOfObjects = exports.findObjectInArray = exports.enumToDropdownData = exports.deepMergeTwoObjects = exports.addPropertyToObjects = exports.roundNumber = exports.numberWithCommas = exports.formattedPercent = exports.formattedMemorySize = exports.formattedDollar = exports.isNullOrUndefined = exports.getRandomCSSColor = exports.getEnvVar = exports.eventLogger = exports.checkArguments = exports.isValidDate = exports.getCurrentDate = exports.addMinutesToDateTime = exports.getRivialColors = exports.updateItemById = exports.union = exports.shuffleArray = exports.not = exports.isNonEmptyArray = exports.isEmptyArray = exports.intersection = void 0;
exports.RATING_SORT_ORDER = exports.toLowerCaseFirstLetter = exports.replaceAsync = exports.removeNonStandardCharactersFromString = exports.parseTextFromHtml = void 0;
// Array
var intersection_1 = require("./functions/Array/intersection");
Object.defineProperty(exports, "intersection", { enumerable: true, get: function () { return intersection_1.intersection; } });
var isEmptyArray_1 = require("./functions/Array/isEmptyArray");
Object.defineProperty(exports, "isEmptyArray", { enumerable: true, get: function () { return isEmptyArray_1.isEmptyArray; } });
var isNonEmptyArray_1 = require("./functions/Array/isNonEmptyArray");
Object.defineProperty(exports, "isNonEmptyArray", { enumerable: true, get: function () { return isNonEmptyArray_1.isNonEmptyArray; } });
var not_1 = require("./functions/Array/not");
Object.defineProperty(exports, "not", { enumerable: true, get: function () { return not_1.not; } });
var shuffleArray_1 = require("./functions/Array/shuffleArray");
Object.defineProperty(exports, "shuffleArray", { enumerable: true, get: function () { return shuffleArray_1.shuffleArray; } });
var union_1 = require("./functions/Array/union");
Object.defineProperty(exports, "union", { enumerable: true, get: function () { return union_1.union; } });
var updateItemById_1 = require("./functions/Array/updateItemById");
Object.defineProperty(exports, "updateItemById", { enumerable: true, get: function () { return updateItemById_1.updateItemById; } });
// Branding
var getRivialColors_1 = require("./functions/Branding/getRivialColors");
Object.defineProperty(exports, "getRivialColors", { enumerable: true, get: function () { return getRivialColors_1.getRivialColors; } });
// DateTime
var addMinutesToDateTime_1 = require("./functions/DateTime/addMinutesToDateTime");
Object.defineProperty(exports, "addMinutesToDateTime", { enumerable: true, get: function () { return addMinutesToDateTime_1.addMinutesToDateTime; } });
var getCurrentDate_1 = require("./functions/DateTime/getCurrentDate");
Object.defineProperty(exports, "getCurrentDate", { enumerable: true, get: function () { return getCurrentDate_1.getCurrentDate; } });
var isValidDate_1 = require("./functions/DateTime/isValidDate");
Object.defineProperty(exports, "isValidDate", { enumerable: true, get: function () { return isValidDate_1.isValidDate; } });
// Misc
var checkArguments_1 = require("./functions/Misc/checkArguments");
Object.defineProperty(exports, "checkArguments", { enumerable: true, get: function () { return checkArguments_1.checkArguments; } });
var eventLogger_1 = require("./functions/Misc/eventLogger");
Object.defineProperty(exports, "eventLogger", { enumerable: true, get: function () { return eventLogger_1.eventLogger; } });
var getEnvVar_1 = require("./functions/Misc/getEnvVar");
Object.defineProperty(exports, "getEnvVar", { enumerable: true, get: function () { return getEnvVar_1.getEnvVar; } });
var getRandomCSSColor_1 = require("./functions/Misc/getRandomCSSColor");
Object.defineProperty(exports, "getRandomCSSColor", { enumerable: true, get: function () { return getRandomCSSColor_1.getRandomCSSColor; } });
var isNullOrUndefined_1 = require("./functions/Misc/isNullOrUndefined");
Object.defineProperty(exports, "isNullOrUndefined", { enumerable: true, get: function () { return isNullOrUndefined_1.isNullOrUndefined; } });
// Number
var formattedDollar_1 = require("./functions/Number/formattedDollar");
Object.defineProperty(exports, "formattedDollar", { enumerable: true, get: function () { return formattedDollar_1.formattedDollar; } });
var formattedMemorySize_1 = require("./functions/Number/formattedMemorySize");
Object.defineProperty(exports, "formattedMemorySize", { enumerable: true, get: function () { return formattedMemorySize_1.formattedMemorySize; } });
var formattedPercent_1 = require("./functions/Number/formattedPercent");
Object.defineProperty(exports, "formattedPercent", { enumerable: true, get: function () { return formattedPercent_1.formattedPercent; } });
var numberWithCommas_1 = require("./functions/Number/numberWithCommas");
Object.defineProperty(exports, "numberWithCommas", { enumerable: true, get: function () { return numberWithCommas_1.numberWithCommas; } });
var roundNumber_1 = require("./functions/Number/roundNumber");
Object.defineProperty(exports, "roundNumber", { enumerable: true, get: function () { return roundNumber_1.roundNumber; } });
// Object
var addPropertyToObjects_1 = require("./functions/Object/addPropertyToObjects");
Object.defineProperty(exports, "addPropertyToObjects", { enumerable: true, get: function () { return addPropertyToObjects_1.addPropertyToObjects; } });
var deepMergeTwoObjects_1 = require("./functions/Object/deepMergeTwoObjects");
Object.defineProperty(exports, "deepMergeTwoObjects", { enumerable: true, get: function () { return deepMergeTwoObjects_1.deepMergeTwoObjects; } });
var enumToDropdownData_1 = require("./functions/Object/enumToDropdownData");
Object.defineProperty(exports, "enumToDropdownData", { enumerable: true, get: function () { return enumToDropdownData_1.enumToDropdownData; } });
var findObjectInArray_1 = require("./functions/Object/findObjectInArray");
Object.defineProperty(exports, "findObjectInArray", { enumerable: true, get: function () { return findObjectInArray_1.findObjectInArray; } });
var flattenArrayOfObjects_1 = require("./functions/Object/flattenArrayOfObjects");
Object.defineProperty(exports, "flattenArrayOfObjects", { enumerable: true, get: function () { return flattenArrayOfObjects_1.flattenArrayOfObjects; } });
var getAllLinkedItems_1 = require("./functions/Object/getAllLinkedItems");
Object.defineProperty(exports, "getAllLinkedItems", { enumerable: true, get: function () { return getAllLinkedItems_1.getAllLinkedItems; } });
var getPropertiesFromObject_1 = require("./functions/Object/getPropertiesFromObject");
Object.defineProperty(exports, "getPropertiesFromObject", { enumerable: true, get: function () { return getPropertiesFromObject_1.getPropertiesFromObject; } });
var hasProperty_1 = require("./functions/Object/hasProperty");
Object.defineProperty(exports, "hasProperty", { enumerable: true, get: function () { return hasProperty_1.hasProperty; } });
var isObject_1 = require("./functions/Object/isObject");
Object.defineProperty(exports, "isObject", { enumerable: true, get: function () { return isObject_1.isObject; } });
var omitDeep_1 = require("./functions/Object/omitDeep");
Object.defineProperty(exports, "omitDeep", { enumerable: true, get: function () { return omitDeep_1.omitDeep; } });
var removeDuplicateObjects_1 = require("./functions/Object/removeDuplicateObjects");
Object.defineProperty(exports, "removeDuplicateObjects", { enumerable: true, get: function () { return removeDuplicateObjects_1.removeDuplicateObjects; } });
var removeObjectFromArray_1 = require("./functions/Object/removeObjectFromArray");
Object.defineProperty(exports, "removeObjectFromArray", { enumerable: true, get: function () { return removeObjectFromArray_1.removeObjectFromArray; } });
var removeObjectKey_1 = require("./functions/Object/removeObjectKey");
Object.defineProperty(exports, "removeObjectKey", { enumerable: true, get: function () { return removeObjectKey_1.removeObjectKey; } });
var sortObjectsByField_1 = require("./functions/Object/sortObjectsByField");
Object.defineProperty(exports, "sortObjectsByField", { enumerable: true, get: function () { return sortObjectsByField_1.sortObjectsByField; } });
var tryParse_1 = require("./functions/Object/tryParse");
Object.defineProperty(exports, "tryParse", { enumerable: true, get: function () { return tryParse_1.tryParse; } });
var updateObjectInArray_1 = require("./functions/Object/updateObjectInArray");
Object.defineProperty(exports, "updateObjectInArray", { enumerable: true, get: function () { return updateObjectInArray_1.updateObjectInArray; } });
// String
var capitalizeFirstLetter_1 = require("./functions/String/capitalizeFirstLetter");
Object.defineProperty(exports, "capitalizeFirstLetter", { enumerable: true, get: function () { return capitalizeFirstLetter_1.capitalizeFirstLetter; } });
var concatNotes_1 = require("./functions/String/concatNotes");
Object.defineProperty(exports, "concatNotes", { enumerable: true, get: function () { return concatNotes_1.concatNotes; } });
var convertCamelCaseToSentence_1 = require("./functions/String/convertCamelCaseToSentence");
Object.defineProperty(exports, "convertCamelCaseToSentence", { enumerable: true, get: function () { return convertCamelCaseToSentence_1.convertCamelCaseToSentence; } });
var convertToKebabCase_1 = require("./functions/String/convertToKebabCase");
Object.defineProperty(exports, "convertToKebabCase", { enumerable: true, get: function () { return convertToKebabCase_1.convertToKebabCase; } });
var createCommaString_1 = require("./functions/String/createCommaString");
Object.defineProperty(exports, "createCommaString", { enumerable: true, get: function () { return createCommaString_1.createCommaString; } });
var decodeSpecialHtml_1 = require("./functions/String/decodeSpecialHtml");
Object.defineProperty(exports, "decodeSpecialHtml", { enumerable: true, get: function () { return decodeSpecialHtml_1.decodeSpecialHtml; } });
var encodeBytesToString_1 = require("./functions/String/encodeBytesToString");
Object.defineProperty(exports, "encodeBytesToString", { enumerable: true, get: function () { return encodeBytesToString_1.encodeBytesToString; } });
var extractHTMLContent_1 = require("./functions/String/extractHTMLContent");
Object.defineProperty(exports, "extractHTMLContent", { enumerable: true, get: function () { return extractHTMLContent_1.extractHTMLContent; } });
var formattedName_1 = require("./functions/String/formattedName");
Object.defineProperty(exports, "formattedName", { enumerable: true, get: function () { return formattedName_1.formattedName; } });
var getBase64ImageDimensions_1 = require("./functions/String/getBase64ImageDimensions");
Object.defineProperty(exports, "getBase64ImageDimensions", { enumerable: true, get: function () { return getBase64ImageDimensions_1.getBase64ImageDimensions; } });
var getFriendlyRating_1 = require("./functions/String/getFriendlyRating");
Object.defineProperty(exports, "getFriendlyRating", { enumerable: true, get: function () { return getFriendlyRating_1.getFriendlyRating; } });
var keywordSort_1 = require("./functions/String/keywordSort");
Object.defineProperty(exports, "keywordSort", { enumerable: true, get: function () { return keywordSort_1.keywordSort; } });
var listToString_1 = require("./functions/String/listToString");
Object.defineProperty(exports, "convertStringArrayIntoOrderedList", { enumerable: true, get: function () { return listToString_1.convertStringArrayIntoOrderedList; } });
var parseTextFromHtml_1 = require("./functions/String/parseTextFromHtml");
Object.defineProperty(exports, "parseTextFromHtml", { enumerable: true, get: function () { return parseTextFromHtml_1.parseTextFromHtml; } });
var removeNonStandardCharactersFromString_1 = require("./functions/String/removeNonStandardCharactersFromString");
Object.defineProperty(exports, "removeNonStandardCharactersFromString", { enumerable: true, get: function () { return removeNonStandardCharactersFromString_1.removeNonStandardCharactersFromString; } });
var replaceAsync_1 = require("./functions/String/replaceAsync");
Object.defineProperty(exports, "replaceAsync", { enumerable: true, get: function () { return replaceAsync_1.replaceAsync; } });
var toLowerCaseFirstLetter_1 = require("./functions/String/toLowerCaseFirstLetter");
Object.defineProperty(exports, "toLowerCaseFirstLetter", { enumerable: true, get: function () { return toLowerCaseFirstLetter_1.toLowerCaseFirstLetter; } });
// Constants
var RATING_SORT_ORDER_1 = require("./constants/RATING_SORT_ORDER");
Object.defineProperty(exports, "RATING_SORT_ORDER", { enumerable: true, get: function () { return RATING_SORT_ORDER_1.RATING_SORT_ORDER; } });
