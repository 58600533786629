import { ReactElement } from "react";

interface DateDisplayProps {
  value?: string;
  dateOnly: boolean;
}

const DateDisplay = ({ value, dateOnly }: DateDisplayProps): ReactElement => {
  return <span>{getDateDisplayString({ value, dateOnly })}</span>;
};

export const getDateDisplayString = ({ value, dateOnly }: DateDisplayProps): string => {
  if (!value || isNaN(new Date(value).getTime())) {
    return "Not Available";
  }

  return dateOnly ? new Date(value).toLocaleDateString() : new Date(value).toLocaleString();
};

export default DateDisplay;
