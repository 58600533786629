"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.threatsByOwnerGroup_systemAccordion = exports.informationAssetByOwnerGroup_systemAccordion = exports.riskControlCategoryByOwnerGroup_systemAccordion = exports.risksByOwnerGroup_systemAccordion = exports.getRiskControlCategory_riskConfig = exports.updateRisk_minimal = exports.getRisk_riskConfigDetails = exports.getSystem_monteCarlo = exports.listRiskChanges_minimal = exports.updateSystemRiskLink_probabilityModifier = exports.deleteSystemControlCategoryLink_removeControlsFromSystem = exports.createSystemControlCategoryLink_attachControlsToSystem = exports.updateSystemRiskLink_defaultMapping = exports.deleteSystemRiskLink_removeKRIsFromSystem = exports.createSystemRiskThreatLink_attachKRIsToSystem = exports.createSystemRiskLink_attachKRIsToSystem = exports.deleteSystemRiskThreatLink_minimal = exports.createSystemRiskThreatLink_minimal = exports.createSystem_minimal = exports.deleteSystemInformationAssetLink_minimal = exports.createSystemInformationAssetLink_minimal = exports.updateSystem_minimal = exports.updateSystemInformationAssetLink_riskChange = exports.listInformationAssets_informationAssetsList = exports.getSystem_systemDetails = exports.getSystem_recommendations = exports.listRiskControls_riskControlList = exports.updateDefaultRiskMapping = exports.getSystem_controlCategories = exports.keyRiskIndicatorsGQL = exports.standardLossAmountGQL = exports.numberOfEmployeesGQL = exports.numberOfCustomersAndAssetSizeGQL = exports.numberOfCustomersGQL = exports.assetSizeGQL = exports.riskMappingGQL = exports.ratingScaleGQL = void 0;
exports.ratingScaleGQL = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        assetSize
        numberOfCustomers
        numberOfEmployees
        riskSetup {
          high
          mediumHigh
          medium
          lowMedium
          low
        },
        lossToleranceCurve {
          amount
          probability
        }
      }
    }
  `;
exports.riskMappingGQL = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        riskMapping {
          dataBreach
          systemDisruption
          facilityDisruption
          fraud
          malware
          vendor
          compliance
        }
      }
    }
  `;
exports.assetSizeGQL = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        assetSize
      }
    }
  `;
exports.numberOfCustomersGQL = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        numberOfCustomers
      }
    }
  `;
exports.numberOfCustomersAndAssetSizeGQL = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        numberOfCustomers
        assetSize
        numberOfEmployees
      }
    }
  `;
exports.numberOfEmployeesGQL = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        numberOfEmployees
      }
    }
  `;
exports.standardLossAmountGQL = `
    query GetOrganization($id: ID!) {
      getOrganization(id: $id) {
        id
        numberOfEmployees
        numberOfCustomers
        assetSize
        riskMapping {
          dataBreach
          systemDisruption
          facilityDisruption
          fraud
          malware
          vendor
          compliance
        }
        riskSetup {
          high
          mediumHigh
          medium
          lowMedium
          low
        }
        lossToleranceCurve {
          amount
          probability
        }
      }
    }
  `;
/*
  risks: [SystemRiskLink] @connection(name: "SystemRiskLink")
  riskOverrides: [RiskOverride]
  riskControlOverrides: [RiskControlOverride]
 */
exports.keyRiskIndicatorsGQL = `
    query GetSystem($id: ID!) {
      getSystem(id: $id) {
        id
        ownerGroup
        risks(limit: 100) {
          items {
            id
            ownerGroup
            probabilityModifier
            cia {
              confidentiality
              integrity
              availability
            }
            threats(limit: 100) {
              items {
                id
                ownerGroup
                threat {
                  id
                  name
                }
              }
            }
            riskMapping {
              dataBreach
              systemDisruption
              facilityDisruption
              fraud
              malware
              vendor
              compliance
            }
            risk {
              id
              name
              classification
              description
              annualRateOfOccurrence
              annualRateOfOccurrenceMax
              confidenceIntervalLower
              confidenceIntervalUpper
              costOfControls
              riskSystemId
              ownerGroup
              controlCategoryID
              defaultRiskMapping {
                dataBreach
                systemDisruption
                facilityDisruption
                fraud
                malware
                vendor
                compliance
              }
              controlCategory {
                id
                name
                subControls(limit: 100) {
                  items {
                    id
                    statementNumber
                    name
                    strengthRating
                    implementationRating
                  }
                }
              }
            }
          }
        }
        controlCategories {
          items {
            id
            ownerGroup
            riskControlCategoryID
          }
        }
        riskOverrides {
          enabledFields
          riskId
          annualRateOfOccurrence
          annualRateOfOccurrenceMax
          confidenceIntervalLower
          confidenceIntervalUpper
          costOfControls
        }
        riskControlOverrides {
          enabledFields
          riskControlId
          costOfControl
          strengthRating
          implementationRating
          implementationDetails
        }
      }
    }
  `;
exports.getSystem_controlCategories = `
    query GetSystem($id: ID!) {
      getSystem(id: $id) {
        id
        ownerGroup
        controlCategories(limit: 100) {
          items {
            id
            riskControlCategory {
              id
              name
              ownerGroup
              subControls(limit: 100) {
                items {
                  id
                  name
                  statementNumber
                  costOfControl
                  strengthRating
                  implementationRating
                  outsourced
                  vendorSubControlID
                  controlCategoryID
                  controlCategory {
                    id
                    name
                    ownerGroup
                  }
                }
              }
            }
          }
        }
        risks(limit: 100) {
          items {
            risk {
              id
              ownerGroup
              controlCategoryID
            }
          }
        }
        riskControlOverrides {
          enabledFields
          riskControlId
          costOfControl
          implementationRating
          outsourced
          implementationDetails
        }
        vendorSolution {
          id
          name
          description
          ownerGroup
        }
      }
    }
  `;
exports.updateDefaultRiskMapping = `
  mutation UpdateRisk($input: UpdateRiskInput!) {
    updateRisk(input: $input) {
      id
      name
      description
      annualRateOfOccurrence
      confidenceIntervalUpper
      confidenceIntervalLower
      defaultRiskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
    }
  }
`;
exports.listRiskControls_riskControlList = `
  query ListRiskControls($filter: ModelRiskControlFilterInput, $limit: Int, $nextToken: String) {
    listRiskControls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        name
        ownerGroup
        keyControl
        statementNumber
        costOfControl
        strengthRating
        implementationRating
        controlCategoryID
        controlCategory {
          id
          name
        }
        notes {
          id
          type
          ownerGroup
          author
          timeStamp
          content
          tag
          observationID
        }
      }
    }
  }
`;
exports.getSystem_recommendations = `
    query GetSystem($id: ID!) {
      getSystem(id: $id) {
        id
        ownerGroup
        riskOverrides {
          enabledFields
          riskId
          annualRateOfOccurrence
          annualRateOfOccurrenceMax
          confidenceIntervalLower
          confidenceIntervalUpper
          costOfControls
        }
        riskControlOverrides {
          enabledFields
          riskControlId
          costOfControl
          implementationDetails
          implementationRating
        }
        controlCategories(limit: 100) {
          items {
            id
            riskControlCategory {
              id
              name
              ownerGroup
              subControls(limit: 100) {
                items {
                  id
                  name
                  statementNumber
                  costOfControl
                  strengthRating
                  implementationRating
                  outsourced
                  controlCategoryID
                  recommendations(limit: 100) {
                    items {
                      id
                      name
                      riskControlID
                      implementationChange
                      costChange
                      returnOnInvestment
                      residualChange
                      system {
                        id
                        name
                        ownerGroup
                      }
                      riskControl {
                        id
                        name
                        statementNumber
                        costOfControl
                        implementationRating
                        strengthRating
                        controlCategoryID
                        controlCategory {
                          id
                          name
                        }
                      }
                    }
                  }
                  controlCategory {
                    id
                    name
                    ownerGroup
                  }
                }
              }
            }
          }
        }
        risks(limit: 100) {
          items {
            id
            ownerGroup
            cia {
              confidentiality
              integrity
              availability
            }
            risk {
              id
              name
              classification
              description
              annualRateOfOccurrence
              annualRateOfOccurrenceMax
              confidenceIntervalLower
              confidenceIntervalUpper
              costOfControls
              riskSystemId
              ownerGroup
              controlCategoryID
              controlCategory {
                id
                name
                ownerGroup
                subControls(limit: 100) {
                  items {
                    id
                    name
                    statementNumber
                    costOfControl
                    strengthRating
                    implementationRating
                    outsourced
                    controlCategoryID
                    controlCategory {
                      id
                      name
                      ownerGroup
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
exports.getSystem_systemDetails = `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      id
      name
      description
      ownerGroup
      monteCarloInputS3ObjectKey
      hosting
      lastReviewed
      residualRisk
      inherentRisk
      lossToleranceDelta
      riskRating
      greatestEnterpriseRisk
      greatestBusinessRisk
      greatestKeyRiskIndicator
      pointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
      }
      adminPointOfContact {
        id
        firstName
        lastName
        title
        email
        phone
        ownerGroup
      }
      accessType
      confidentiality
      integrity
      availability
      availabilityUpper
      availabilityLower
      targets {
        items {
          id
          ownerGroup
          target {
            id
            hostName
            ip
            macAddress
            lastTestedDate
          }
        }
        nextToken
      }
      tags(limit: 100) {
        items {
          __typename
          id
          tag {
            id
            name
            description
            fontColor
            backgroundColor
          }
        }
      }
      questionnaires(limit: 100) {
        items {
          id
          ownerGroup
          systemID
          questionnaireID
          questionnaire {
            id
            name
          }
        }
        nextToken
      }
      risks(limit: 100) {
        items {
          id
          ownerGroup
          probabilityModifier
          risk {
            id
            name
            ownerGroup
            controlCategoryID
            annualRateOfOccurrence
          }
          cia {
            confidentiality
            integrity
            availability
          }
          riskMapping {
            dataBreach
            systemDisruption
            facilityDisruption
            fraud
            malware
            vendor
            compliance
          }
        }
        nextToken
      }
      riskOverrides {
        enabledFields
        riskId
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
      }
      riskControlOverrides {
        enabledFields
        riskControlId
        costOfControl
        strengthRating
        implementationRating
        implementationDetails
        outsourced
      }
      informationAssets(limit: 100) {
        items {
          id
          systemID
          informationAssetID
          informationAsset {
            id
            name
            confidentiality
            integrity
            ownerGroup
            confidentialityPerRecord
            integrityPerRecord
          }
          numberOfRecords
          confidentialityPerRecord
          integrityPerRecord
        }
        nextToken
      }
      evidenceRiskControls(limit: 500) {
        items {
          id
        }
        nextToken
      }
      controlCategories(limit: 100) {
        items {
          id
          ownerGroup
          riskControlCategoryID
          systemID
          riskControlCategory {
            id
            name
            ownerGroup
            description
          }
        }
        nextToken
      }
      vendorSolution {
        id
        name
        vendorReviews(limit: 100) {
          items {
            id
            name
            rating
            status
          }
        }
      }
    }
  }
`;
exports.listInformationAssets_informationAssetsList = `
  query ListInformationAssets($filter: ModelInformationAssetFilterInput, $limit: Int, $nextToken: String) {
    listInformationAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        confidentiality
        integrity
        confidentialityPerRecord
        integrityPerRecord
      }
      nextToken
    }
  }
`;
exports.updateSystemInformationAssetLink_riskChange = `
  mutation UpdateSystemInformationAssetLink($input: UpdateSystemInformationAssetLinkInput!) {
    updateSystemInformationAssetLink(input: $input) {
      id
    }
  }
`;
exports.updateSystem_minimal = `
  mutation UpdateSystem($input: UpdateSystemInput!) {
    updateSystem(input: $input) {
      id
      name
      description
    }
  }
`;
exports.createSystemInformationAssetLink_minimal = `
  mutation CreateSystemInformationAssetLink($input: CreateSystemInformationAssetLinkInput!) {
    createSystemInformationAssetLink(input: $input) {
      id
    }
  }
`;
exports.deleteSystemInformationAssetLink_minimal = `
  mutation DeleteSystemInformationAssetLink($input: DeleteSystemInformationAssetLinkInput!) {
    deleteSystemInformationAssetLink(input: $input) {
      id
    }
  }
`;
exports.createSystem_minimal = `
  mutation CreateSystem($input: CreateSystemInput!) {
    createSystem(input: $input) {
      id
      name
      ownerGroup
    }
  }
`;
exports.createSystemRiskThreatLink_minimal = `
  mutation CreateSystemRiskThreatLink($input: CreateSystemRiskThreatLinkInput!) {
    createSystemRiskThreatLink(input: $input) {
      id
      ownerGroup
    }
  }
`;
exports.deleteSystemRiskThreatLink_minimal = `
  mutation DeleteSystemRiskThreatLink($input: DeleteSystemRiskThreatLinkInput!) {
    deleteSystemRiskThreatLink(input: $input) {
      id
      ownerGroup
    }
  }
`;
exports.createSystemRiskLink_attachKRIsToSystem = `
  mutation CreateSystemRiskLink($input: CreateSystemRiskLinkInput!) {
    createSystemRiskLink(input: $input) {
      id
      ownerGroup
    }
  }
`;
exports.createSystemRiskThreatLink_attachKRIsToSystem = `
  mutation CreateSystemRiskThreatLink($input: CreateSystemRiskThreatLinkInput!) {
    createSystemRiskThreatLink(input: $input) {
      id
      ownerGroup
    }
  }
`;
exports.deleteSystemRiskLink_removeKRIsFromSystem = `
  mutation DeleteSystemRiskLink($input: DeleteSystemRiskLinkInput!) {
    deleteSystemRiskLink(input: $input) {
      id
      ownerGroup
    }
  }
`;
exports.updateSystemRiskLink_defaultMapping = `
  mutation UpdateSystemRiskLink($input: UpdateSystemRiskLinkInput!) {
    updateSystemRiskLink(input: $input) {
      id
      ownerGroup
    }
  }
`;
exports.createSystemControlCategoryLink_attachControlsToSystem = `
  mutation CreateSystemControlCategoryLink($input: CreateSystemControlCategoryLinkInput!) {
    createSystemControlCategoryLink(input: $input) {
      id
      ownerGroup
    }
  }
`;
exports.deleteSystemControlCategoryLink_removeControlsFromSystem = `
  mutation DeleteSystemControlCategoryLink($input: DeleteSystemControlCategoryLinkInput!) {
    deleteSystemControlCategoryLink(input: $input) {
      id
      ownerGroup
    }
  }
`;
exports.updateSystemRiskLink_probabilityModifier = `
  mutation UpdateSystemRiskLink($input: UpdateSystemRiskLinkInput!) {
    updateSystemRiskLink(input: $input) {
      id
      ownerGroup
      probabilityModifier
    }
  }
`;
exports.listRiskChanges_minimal = `
  query ListRiskChanges($filter: ModelRiskChangeFilterInput, $limit: Int, $nextToken: String) {
    listRiskChanges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerGroup
        name
        type
        description
        date
        userEmail
        changeOwner
        reason
        change
      }
      nextToken
    }
  }
`;
exports.getSystem_monteCarlo = `
  query GetSystem($id: ID!) {
    getSystem(id: $id) {
      id
      name
      description
      ownerGroup
      hosting
      availability
      informationAssets(limit: 100) {
        items {
          id
          numberOfRecords
          informationAsset {
            id
            name
            ownerGroup
            confidentiality
            integrity
          }
        }
      }
      risks(limit: 100) {
        items {
          id
          ownerGroup
          probabilityModifier
          riskMapping {
            dataBreach
            systemDisruption
            facilityDisruption
            fraud
            malware
            vendor
            compliance
          }
          cia {
            confidentiality
            integrity
            availability
          }
          risk {
            id
            name
            ownerGroup
            annualRateOfOccurrence
            controlCategoryID
            controlCategory {
              id
              name
              ownerGroup
              description
              subControls(limit: 100) {
                items {
                  id
                  name
                  statementNumber
                  costOfControl
                  strengthRating
                  implementationRating
                  outsourced
                  ownerGroup
                  controlCategoryID
                }
                nextToken
              }
            }
          }
        }
      }
      riskOverrides {
        enabledFields
        riskId
        annualRateOfOccurrence
        annualRateOfOccurrenceMax
        confidenceIntervalLower
        confidenceIntervalUpper
        costOfControls
      }
      riskControlOverrides {
        enabledFields
        riskControlId
        costOfControl
        implementationDetails
        strengthRating
        implementationRating
        outsourced
      }
    }
  }
`;
exports.getRisk_riskConfigDetails = `
  query GetRisk($id: ID!) {
    getRisk(id: $id) {
      id
      name
      classification
      description
      annualRateOfOccurrence
      ownerGroup
      controlCategoryID
      controlCategory {
        id
        name
      }
      defaultRiskMapping {
        dataBreach
        systemDisruption
        facilityDisruption
        fraud
        malware
        vendor
        compliance
      }
    }
  }
`;
exports.updateRisk_minimal = `
  mutation UpdateRisk($input: UpdateRiskInput!) {
    updateRisk(input: $input) {
      id
      name
      description
      annualRateOfOccurrence
    }
  }
`;
exports.getRiskControlCategory_riskConfig = `
  query GetRiskControlCategory($id: ID!) {
    getRiskControlCategory(id: $id) {
      id
      name
      ownerGroup
      description
      subControls {
        items {
          id
          name
          statementNumber
          costOfControl
          strengthRating
          implementationRating
          outsourced
          ownerGroup
          controlCategoryID
        }
      }
    }
  }
`;
exports.risksByOwnerGroup_systemAccordion = `
  query RisksByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelRiskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    risksByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        annualRateOfOccurrence
        controlCategoryID
        defaultRiskMapping {
          dataBreach
          systemDisruption
          facilityDisruption
          fraud
          malware
          vendor
          compliance
        }
      }
      nextToken
    }
  }
`;
exports.riskControlCategoryByOwnerGroup_systemAccordion = `
  query RiskControlCategoryByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelRiskControlCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    riskControlCategoryByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        ownerGroup
        description
      }
      nextToken
    }
  }
`;
exports.informationAssetByOwnerGroup_systemAccordion = `
  query InformationAssetByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelInformationAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    informationAssetByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        confidentiality
        confidentialityPerRecord
        integrityPerRecord
        integrity
        ownerGroup
      }
      nextToken
    }
  }
`;
exports.threatsByOwnerGroup_systemAccordion = `
  query ThreatsByOwnerGroup(
    $ownerGroup: String
    $sortDirection: ModelSortDirection
    $filter: ModelThreatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    threatsByOwnerGroup(
      ownerGroup: $ownerGroup
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        techniqueID
        ownerGroup
      }
      nextToken
    }
  }
`;
