import { GridFilterInputValue, GridFilterOperator } from "@mui/x-data-grid-pro";

const notContainsOperator: GridFilterOperator = {
  value: "notContains",
  getApplyFilterFn: (filterItem) => {
    if (!filterItem.value) return null;
    return (value, row, column, apiRef) => {
      const valueToCheck = String(value).toLowerCase();
      const formattedValue = apiRef?.current?.getRowFormattedValue(row, column);
      const formattedValueToCheck = String(formattedValue).toLowerCase();
      const filterValue = filterItem.value.toLowerCase();
      return !(valueToCheck.includes(filterValue) || formattedValueToCheck.includes(filterValue));
    };
  },
  InputComponent: GridFilterInputValue,
  label: "not contains",
};

export const insertNotContainsOperator = (existingOperators: GridFilterOperator[]): GridFilterOperator[] => {
  const containsIndex = existingOperators.findIndex((op) => op.value === "contains");
  if (containsIndex === -1) return [...existingOperators, notContainsOperator];

  return [
    ...existingOperators.slice(0, containsIndex + 1),
    notContainsOperator,
    ...existingOperators.slice(containsIndex + 1),
  ];
};
