import _ from "lodash";
import { matchSorter } from "match-sorter";
import React, { useEffect, useState } from "react";

import { isNonEmptyArray } from "@rivial-security/func-utils";

import { useDebounce } from "../../../functional/useDebounce";

/**
 * Handles state and search logic for the DataGrid "Quick Filter" functionality.
 * The array returned from this hook is used directly by the grid to render data
 *    See: https://mui.com/components/data-grid/filtering/#quick-filter
 * @param {object[]} data - data grid input state
 * @param {string} columns - the current field configurations for the data grid
 * @returns {{searchText: string, requestSearch: requestSearch}}
 */
export const useDataGridSearch = ({ data, columns }) => {
  /**
   * Current search text
   */
  const [searchText, setSearchText] = React.useState("");

  /**
   * Holds all initial data in state
   */
  const [searchResults, setSearchResults] = useState([]);

  /**
   * Used to force a re-render of the data grid when the data changes
   */
  const [dataKey, setDataKey] = useState(1);

  /**
   * when data changes, add 1 to the dataKey to force a re-render of the data grid
   */
  useEffect(() => {
    if (isNonEmptyArray(data)) {
      setDataKey((dataKey) => dataKey + 1);
    }
  }, [data]);
  /**
   * Actually performs the search and sets the result data state
   * @param searchValue
   */
  const handleSearch = (searchValue) => {
    const filteredRows = fuzzySearch({ rows: data, searchValue, columns });
    setSearchResults(filteredRows);
  };

  /**
   * Sets the search text state, but the search function runs through a debounce
   * @param searchValue
   */
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
  };

  /**
   * Adds a debounce to delay the search while typing
   */
  useDebounce({
    value: searchText,
    func: handleSearch,
    deps: [searchText, dataKey],
  });

  return {
    searchText,
    requestSearch,
    searchResults,
  };
};

/**
 * Performs a more advanced search through the data.
 * @param {object[]} rows - the data state from the grid, used to perform search
 * @param {string} searchValue - the current search string value
 * @param {string} columns - the current field configurations for the data grid
 * @returns {Array<string>|*}
 */
const fuzzySearch = ({ rows, searchValue, columns }) => {
  const searchData = _.cloneDeep(rows);

  let keys = Array.isArray(rows) && rows.length > 0 ? Object.keys(rows?.[0]) : [];

  // remove the ID and ownerGroup field from search
  if (keys.includes("id")) {
    keys = keys.filter((e) => e !== "id" && e !== "ownerGroup");
  }

  if (!searchValue || !searchValue.length) {
    return rows;
  }

  //Add any custom search keys passed in from field configs
  if (Array.isArray(columns)) {
    for (const field of columns) {
      const searchKeys = field?.searchKeys;
      if (Array.isArray(searchKeys)) {
        for (const searchKey of searchKeys) {
          if (typeof searchKey === "string") {
            keys.push(searchKey);
          }
        }
      }
    }
  }

  // if there is a valueGetter function, apply it to the data before searching
  if (Array.isArray(columns)) {
    for (const field of columns) {
      // if there is not a searchValueGetter function and there is a field.valueOptions, set the searchValueGetter based on the valueOptions
      if (!field?.searchValueGetter && field?.valueOptions) {
        field.searchValueGetter = (_value, row) => {
          const value = row[field?.field || field?.name];
          const valueOptions = field?.valueOptions;
          if (Array.isArray(valueOptions)) {
            for (const option of valueOptions) {
              if (option?.value === value) {
                return option?.label;
              }
            }
          }
          return value;
        };
      } else {
        const searchValueGetter = field?.searchValueGetter;
        if (typeof searchValueGetter === "function") {
          for (const row of searchData) {
            const fieldName = field?.field || field?.name;
            row[`${fieldName}_search`] = searchValueGetter(row[fieldName], row);
            keys.push(`${fieldName}_search`);
          }
        }
      }
    }
  }

  // Uses matchSorter to perform fancy search.
  //    See: https://github.com/kentcdodds/match-sorter
  return matchSorter(searchData, searchValue, {
    keys,
    threshold: matchSorter.rankings.CONTAINS,
  });
};
