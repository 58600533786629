import { Icon } from "@iconify/react";
import Assessment from "@mui/icons-material/Assessment";
import AssignmentInd from "@mui/icons-material/AssignmentInd";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Description from "@mui/icons-material/Description";
import Event from "@mui/icons-material/Event";
import FileCopyRounded from "@mui/icons-material/FileCopyRounded";
import HowToReg from "@mui/icons-material/HowToReg";
import OfflineBolt from "@mui/icons-material/OfflineBolt";
import Snooze from "@mui/icons-material/Snooze";
import Timer from "@mui/icons-material/Timer";
import React from "react";

import { EVIDENCE_ACTIVITY_TYPES } from "../../../../../../typedefs/Compliance/Evidence/Evidence";

/**
 * Returns the icon used in the timeline dot based on Activity type
 * @param {EvidenceActivityType} type
 * @returns {JSX.Element}
 */
export const getDotIcon = (type) => {
  switch (type) {
    case EVIDENCE_ACTIVITY_TYPES.REPORT_UPLOAD:
      return <Assessment />;
    case EVIDENCE_ACTIVITY_TYPES.DOCUMENT_UPLOAD:
      return <Description />;
    case EVIDENCE_ACTIVITY_TYPES.EXPIRING_SOON:
      return <Timer />;
    case EVIDENCE_ACTIVITY_TYPES.VALIDATED:
      return <CheckCircleOutline />;
    case EVIDENCE_ACTIVITY_TYPES.EXPIRED:
      return <Snooze />;
    case EVIDENCE_ACTIVITY_TYPES.PENDING_VALIDATION:
      return <HowToReg />;
    case EVIDENCE_ACTIVITY_TYPES.ATTESTED:
      return <AssignmentInd />;
    case EVIDENCE_ACTIVITY_TYPES.MEETING:
      return <Event />;
    case EVIDENCE_ACTIVITY_TYPES.POLICY:
      return <FileCopyRounded />;
    case EVIDENCE_ACTIVITY_TYPES.RISK_CHANGE:
      return <OfflineBolt />;
    case EVIDENCE_ACTIVITY_TYPES.EVIDENCE_CREATED:
      return <i className="icon-plus" />;
    case EVIDENCE_ACTIVITY_TYPES.PHISHING:
      return <i className="icon-user-follow" />;
    case EVIDENCE_ACTIVITY_TYPES.TRAINING:
      return <i className="icon-user-following" />;
    case EVIDENCE_ACTIVITY_TYPES.EXTERNAL_URL:
      return <Icon icon="akar-icons:link-chain" width="22" height="22" />;
    case EVIDENCE_ACTIVITY_TYPES.POINT_OF_CONTACT_ASSIGNED:
      return <Icon icon="pajamas:assignee" width="22" height="22" />;
    case EVIDENCE_ACTIVITY_TYPES.POINT_OF_CONTACT_UNASSIGNED:
      return <Icon icon="pajamas:unassignee" width="22" height="22" />;
    case EVIDENCE_ACTIVITY_TYPES.TYPE_CHANGED:
      return <Icon icon="ic:baseline-change-circle" width="22" height="22" />;
    case EVIDENCE_ACTIVITY_TYPES.STATUS_CHANGED_MANUALLY:
      return <Icon icon="fluent:status-16-filled" width="22" height="22" />;
    case EVIDENCE_ACTIVITY_TYPES.FREQUENCY_CHANGED:
      return <Icon icon="ant-design:calendar-filled" width="22" height="22" />;
    default:
      return <div />; // returning an empty div if there's no icon available
  }
};
