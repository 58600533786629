import { ListQueryBy } from "@utils/Functions/Graphql/ListQueryBy";

import { activityByEvidence } from "../graphql/activityByEvidence";

/**
 * @description Get Evidence Activities function
 * @param {object} item - evidence
 * @param {function} setItem - set item function
 * @param {function} setSortedActivity - set sorted evidence activities function
 * @param {function} setIsLoading - set loading state function
 * @returns {Promise<* | *[]>}
 */
export const getEvidenceActivities = async ({ item, setItem, setSortedActivity, setIsLoading }) => {
  if (item?.id) {
    if (typeof setIsLoading === "function") setIsLoading(true);
    return await ListQueryBy({
      query: activityByEvidence,
      variables: {
        evidenceID: item?.id,
      },
      filter: {
        archived: { ne: true },
      },
    })
      .then((items) => {
        const sortedArray = items.sort((a, b) => new Date(b.date) - new Date(a.date));

        setSortedActivity?.(sortedArray);

        setItem?.((data) => {
          Object.assign(data, { activity: { items: sortedArray } });
          return { ...data };
        });

        return sortedArray;
      })
      .finally(() => {
        if (typeof setIsLoading === "function") setIsLoading(false);
      });
  }
};
