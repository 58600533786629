import { Auth } from "@aws-amplify/auth";
import { CookieStorage } from "amazon-cognito-identity-js";

export interface TokenCache {
  token: string;
  expiration: number;
}

export interface TokenManager {
  getValidToken(): Promise<string>;
}

export class AmplifyTokenManager implements TokenManager {
  private readonly auth: typeof Auth;
  private readonly COOKIE_ACCESS_TOKEN_KEY = "accessToken";
  private readonly cookieStorage: CookieStorage;
  private readonly secondsInMs = 1000;
  private readonly bufferInMs = 30 * 1000;
  private initialized = false;

  constructor(auth = Auth) {
    this.auth = auth;
    this.cookieStorage = this.initializeCookieStorage();
  }

  private initializeCookieStorage(): CookieStorage {
    const oneDay = 1;

    return new CookieStorage({
      secure: window.location.protocol === "https:",
      sameSite: "strict",
      path: "/",
      expires: oneDay,
      domain: window.location.hostname,
    });
  }

  private getCachedToken(): TokenCache | null {
    try {
      const cachedData = this.cookieStorage.getItem(this.COOKIE_ACCESS_TOKEN_KEY);

      if (!cachedData) return null;

      const tokenCache: TokenCache = JSON.parse(cachedData);

      return tokenCache;
    } catch (e) {
      console.error("Error retrieving cached token", e);

      return null;
    }
  }

  private isTokenValid(tokenCache: TokenCache): boolean {
    const now = Date.now();

    return this.initialized && tokenCache.expiration > now + this.bufferInMs;
  }

  private async refreshToken(): Promise<TokenCache> {
    const session = await this.auth.currentSession();
    const accessToken = session.getAccessToken();

    const newTokenCache: TokenCache = {
      token: accessToken.getJwtToken(),
      expiration: accessToken.getExpiration() * this.secondsInMs,
    };

    this.cookieStorage.setItem(this.COOKIE_ACCESS_TOKEN_KEY, JSON.stringify(newTokenCache));

    return newTokenCache;
  }

  public async getValidToken(): Promise<string> {
    const cachedToken = this.getCachedToken();

    if (cachedToken && this.isTokenValid(cachedToken)) {
      return cachedToken.token;
    }

    try {
      const newToken = await this.refreshToken();
      this.initialized = true;

      return newToken.token;
    } catch (error) {
      this.cookieStorage.removeItem(this.COOKIE_ACCESS_TOKEN_KEY);

      throw error;
    }
  }

  public setInitialized(initialized: boolean): void {
    this.initialized = initialized;
  }
}
