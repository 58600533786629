import cx from "classnames";
import React, { forwardRef, HTMLAttributes, ReactElement } from "react";

import { TextColor, ThemeColor } from "@components/Ions/colors/constants/Color";

import styles from "./Text.module.scss";

export type TextHtmlElements = keyof Pick<React.JSX.IntrinsicElements, "p" | "div" | "span" | "label" | "li">;

export interface TextProps extends HTMLAttributes<HTMLElement> {
  /** The variant of the text (example: `subtitle1`, `body1`). */
  variant?: TextVariant;
  /** The text to display. */
  children: React.ReactNode;
  /** The color of the text. */
  color?: TextColor | ThemeColor;
  /** The HTML element to render. This gives the ability to render a different element than the variant while keeping the same styling. */
  htmlElement?: TextHtmlElements;
  /** Used when the `Text` component is rendered as a label */
  htmlFor?: string;
  /** Additional class names to apply to the text element. */
  className?: string;
}

export enum TextVariant {
  SUBTITLE1 = "subtitle1",
  SUBTITLE2 = "subtitle2",
  SUBTITLE3 = "subtitle3",
  BODY1 = "body1",
  BODY2 = "body2",
  BODY3 = "body3",
  CAPTION = "caption",
}

const defaultTagMapping: { [key in TextVariant]?: TextHtmlElements } = {
  [TextVariant.SUBTITLE1]: "p",
  [TextVariant.SUBTITLE2]: "p",
  [TextVariant.SUBTITLE3]: "p",
  [TextVariant.BODY1]: "p",
  [TextVariant.BODY2]: "p",
  [TextVariant.BODY3]: "p",
  [TextVariant.CAPTION]: "span",
};

/**
 * Used to display text in different variants.  All text rendered on the platform should use a `Text` component.
 */
const Text = forwardRef<TextHtmlElements | HTMLSpanElement | HTMLLabelElement, TextProps>(
  (
    { variant = TextVariant.BODY2, children, color, htmlElement, htmlFor, className, ...rest }: TextProps,
    ref,
  ): ReactElement => {
    const textHtmlElement = htmlElement ?? defaultTagMapping[variant] ?? "span";
    const elementClassName = cx(styles.font, styles[variant], styles[`color-${color}`], className);
    return React.createElement(
      textHtmlElement,
      {
        className: elementClassName,
        ...(textHtmlElement === "label" && htmlFor ? { htmlFor } : {}),
        ...rest,
        ref,
      },
      children,
    );
  },
);

Text.displayName = "Text";

export default Text;
