import { useCallback, useContext } from "react";

import { UIContext } from "@utils/Context/UIContext";

import { helpCenterUrl } from "../../../env-config";

interface UseOpenArticleProps {
  /**
   * The route segment to append to the base help center URL
   * @example "automation-steps"
   */
  helpCenterRoute?: string;
  /**
   * Optional full URL that overrides the base help center URL + route
   * @example "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#AutomationSteps-UpdateResourceAutomation"
   */
  overrideUrl?: string;
}

/**
 * Hook to open the Help Center to a specific article
 *
 * @param props Configuration options
 *
 * @example
 * // Open using base URL + route
 * const openArticle = useOpenArticle({
 *   helpCenterRoute: "automation-steps"
 * });
 *
 * @example
 * // Open using override URL
 * const openArticle = useOpenArticle({
 *   overrideUrl: "https://rivial-helpcenter.scrollhelp.site/hcenter/automation-steps#section"
 * });
 */
export function useOpenArticle({ helpCenterRoute, overrideUrl }: UseOpenArticleProps): VoidFunction {
  const { helpCenter } = useContext(UIContext);

  return useCallback(() => {
    const newLink = overrideUrl ?? helpCenterUrl + helpCenterRoute;
    helpCenter?.setHelpCenterLink(newLink);
    helpCenter?.setIsOpen(true);
  }, [helpCenter, overrideUrl, helpCenterRoute]);
}
