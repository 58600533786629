import { ErrorLogger } from "@utils/EventLogger";
import { deepDelete } from "@utils/Functions/deepDelete";
import { resources } from "role-utils";

/**
 * Deletes an Action Item resource in the DB with all linked resources.
 * @param {string[]} itemsIDs - array of items IDs to delete
 * @param {object} config - configuration object
 * @param {boolean} config.deleteRecommendations - determines if we should delete all Recommendations associated with this Action Item
 * @param {boolean} config.deleteObservations - pass through for the deleteRecommendation function
 * @param {function} onJobStarted - callback function to get job
 * @returns {Promise<*>}
 */
export const deleteActionItem = async (
  itemsIDs,
  { deleteRecommendations = false, deleteObservations = false },
  onJobStarted,
) => {
  if (!Array.isArray(itemsIDs)) {
    ErrorLogger("[deleteActionItem] Missing ActionItem IDs array");
    return;
  }

  return await deepDelete({
    typename: resources.ACTION_ITEM,
    itemsIDs,
    config: {
      deleteRecommendations,
      deleteObservations,
    },
    onJobStarted,
  });
};
