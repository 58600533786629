"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prepareFunctionArray = void 0;
/**
 * Takes functions from widget config and aranges them into an order that will populate all dependencies.
 * MathExpression functions are sorted last, if both are mathExpression they are sorted by priority
 */
const prepareFunctionArray = ({ functions }) => {
    const functionsArray = Object.values(functions);
    functionsArray.sort((a, b) => {
        const aIsMathExpression = a.name === "mathExpression";
        const bIsMathExpression = b.name === "mathExpression";
        if (aIsMathExpression && !bIsMathExpression)
            return 1;
        if (!aIsMathExpression && bIsMathExpression)
            return -1;
        let aPriority = parseInt(a.config?.priority ?? "0");
        let bPriority = parseInt(b.config?.priority ?? "0");
        if (isNaN(aPriority) || !isFinite(aPriority))
            aPriority = 0;
        if (isNaN(bPriority) || !isFinite(bPriority))
            bPriority = 0;
        return bPriority - aPriority;
    });
    return functionsArray;
};
exports.prepareFunctionArray = prepareFunctionArray;
